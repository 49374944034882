import React, { useCallback, useEffect,  useState } from 'react'
import { getProductPlans } from '../../service/api';
import StarIcon from "../../assets/icons/star.svg";
import AerrowBottom from "../../assets/icons/aerrow-bottom.svg";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from 'react-router-dom';
import SinglePaw from "../../assets/icons/SinglePaw.svg";
import RightCheck from "../../assets/icons/rightCheck.svg";
import BookingPlanCard from '../../component/Card/BookingPlanCard';

export default function Packages(props) {
    const {cityId, cityName} = props;
    const navigate = useNavigate();
    const [dogBookingPlans, setDogBookingPlans] = useState([]);
    const [catBookingPlans, setCatBookingPlans] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [planLoader, setPlanLoader] = useState(true);
    const [isLogin, setIsLogin] = useState(false);
   
  useEffect(() => {
    const updateWindowDimensions = () => {
      setIsMobile(window.innerWidth < 768);
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

    const fetchPlans = async () => {
        try {
         
          const bodySendDog = {
            category_id: "1",
            city_id: cityId,
            isMultipleCity: true,
            pet:"Dog"
          };
          const planDataDog = await getProductPlans(bodySendDog);
     
          
          
          const bodySendCat = {
            category_id: "1",
            city_id: cityId,
            isMultipleCity: true,
            pet:"Cat"
          };
          const planDataCat = await getProductPlans(bodySendCat);

          setDogBookingPlans(planDataDog.data);

    
          setCatBookingPlans(planDataCat.data);

          
          setPlanLoader(false);
          // if (  || planDataCat.data.length !== 0  && planDataCat.success === '1') {
          //   setPlanLoader(false);
          // }
        } catch (error) {
          // console.error("Error fetching cities:", error);
        }
      };

      useEffect(() => {
        document.title = `DearPet - Professional Pet Groomers ${cityName ? `in ${cityName}` : ''}`;
        fetchPlans();
      },[]);

      const handleBookNow = (plan) => {
        localStorage.setItem("selectedPlan", JSON.stringify(plan));
        if (isLogin) {
          navigate("/mypets");
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 500);
        } else {
          navigate("/personal-details");
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 500);
        }
      };


  return (
    <>
    <div className="container py-2 pb-3">
        <h5 className="sec4Heading my-3 text-center mt-4 mb-4">{dogBookingPlans.length !== 0 ? `Dog Grooming Plans in ${cityName}` : ""} </h5>

        {planLoader && (
          <div className="d-flex justify-content-center">
            <ThreeDots color="#7B5BFB" height={50} width={50} />
          </div>
        )}

        {!planLoader && (
          <div className="row justify-content-center bookingCardContainer" style={{width: isMobile ? "100%" : "90%", margin:"0 auto"}}>
            {dogBookingPlans.map((plan, index) => (
              <BookingPlanCard key={index} plan={plan} index={index} length={dogBookingPlans.length} isMobile={isMobile} handleBookNow={handleBookNow} />
            ))}
          </div>
        )}

<h5 className="sec4Heading my-3 text-center mt-4 mb-4">{catBookingPlans.length !== 0 ?  `Cat Grooming Plans in ${cityName}` : ""}</h5>

{planLoader && (
  <div className="d-flex justify-content-center">
    <ThreeDots color="#7B5BFB" height={50} width={50} />
  </div>
)}

{!planLoader && (
  <div className="row justify-content-center bookingCardContainer" style={{width: isMobile ? "100%" : "90%", margin:"0 auto"}}>
    {catBookingPlans.map((plan, index) => (
      <BookingPlanCard key={index} plan={plan} index={index} length={catBookingPlans.length} isMobile={isMobile} handleBookNow={handleBookNow} />
    ))}
  </div>
)}
      </div>
    </>
  )
}
