import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import LeftImg from "../../assets/images/Personal site-amico.svg";
// import PhoneInput from "react-phone-number-input";
// import { parsePhoneNumber } from "react-phone-number-input";
// import "react-phone-number-input/style.css";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import MapComponent from "./MapComponent";
import {
    ReschaduleOrder,
    activeAccount,
    addPet,
    addToCart,
    getCities,
    getStates,
    getTimeSlotes,
    loginWithOtp,
    saveAddress,
    sendOtp,
    userDetails,
} from "../../service/api";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { setAuthToken } from "./../../service/apiService";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const fetchTimeSlotsForDate = async (date, city_id) => {
    try {
        const timeSlotData = await getTimeSlotes(date, city_id);
        return timeSlotData.data;
    } catch (error) {
        console.error("Error fetching TimeSlots:", error);
        return [];
    }
};

const AddressDetails = () => {
    const autocompleteRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [latitude, setLatitude] = useState('23.022505');
    const [longitude, setLongitude] = useState('72.5713621');
    const [selectedPlace, setSelectedPlace] = useState(null);
    const currentDate = new Date();
    const today = new Date();
    const [date, setDate] = useState(currentDate.toISOString().split("T")[0]);
    const [TimeSlotDatas, setTimeSlotDatas] = useState([]);
    const [selectedTimeSlote, setSelectedTimeSlote] = useState();
    const [timeSlotError, setTimeSlotError] = useState("");

    const navigate = useNavigate();
    // const [location, setLocation] = useState(null);
    const [otpState, setOtpState] = useState(false);
    const [petId, setPetId] = useState();
    const location = useLocation();
    const fromWhich = location.state?.fromWhich || "";
    const [otp, setOtp] = useState("");

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [countryCode, setCountryCode] = useState("+91");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const [locality, setLocality] = useState("");
    const [houseNo, setHouseNo] = useState('');
    const [pincode, setPincode] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCityId, setSelectedCityId] = useState();
    const [selectedStateId, setSelectedStateId] = useState();
    const [verified, setVerified] = useState(false);
    const [nationalNumber, setNationalNumber] = useState();

    const [allUData, setAllUData] = useState([]);

    const [phoneError, setPhoneError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [houseNoError, setHouseNoError] = useState("");
    const [localityError, setLocalityError] = useState("");
    const [stateError, setStateError] = useState("");
    const [cityError, setCityError] = useState("");
    const [pincodeError, setPincodeError] = useState("");
    const [otpError, setOtpError] = useState("");
    const [phoneTouched, setPhoneTouched] = useState(false);

    const [otpBtnState, setOtpBtnState] = useState(false);
    const [loginApiCall, setLoginApiCall] = useState();
    const [otpSendSts, setOtpSendSts] = useState(false);

    const [authTokens, setAuthTokens] = useState();
    const [googleAutocomplete, setGoogleAutocomplete] = useState();
    const [inputSearchValue, setInputSearchValue] = useState();


    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'instant'
        });
      },[])

    useEffect(() => {
        fetchCities();
        fetchStates();
    }, []);



    useEffect(() => {
        const uData = JSON.parse(localStorage.getItem("userIdData"));
        const homeCity = JSON.parse(localStorage.getItem("homeData"));
        if (uData) {
            setAllUData(uData);
            const mynum = uData.country_code + uData.phone_number;
            setMobile(mynum);
            setNationalNumber(uData.phone_number);
            setFname(uData.fname);
            // setLname(uData.lname);
            setEmail(uData.email);
            setCountryCode(uData.country_code);

            // setSelectedState(uData.state);
            // setSelectedCity(uData.city);
            // setAddress(uData.address);
            // setPincode(uData.pincode);
            // setSelectedCityId(uData.selectedCityId);
            // setSelectedStateId(uData.selectedStateId);
            // setVerified(uData.verified);
        }

        if (homeCity) {
            setSelectedCity(homeCity.selectedCity.title);
            setSelectedCityId(homeCity.selectedCityId);
            setSelectedStateId(homeCity.selectedCity.state_id);
        }
    }, []);




    const fetchCities = async () => {
        try {
            const cityData = await getCities();

            setCities(cityData.data);
        } catch (error) {
            console.error("Error fetching cities:", error);
        }
    };

    const fetchStates = async () => {
        try {
            const stateData = await getStates();
            setStates(stateData.data);
        } catch (error) {
            console.error("Error fetching states:", error);
        }
    };

    const handlePlaceSelected = (place) => {
        if (place.geometry) {
            setSelectedPlace(place);
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setLatitude(lat);
            setLongitude(lng);
            setGoogleAutocomplete(place);
            setInputSearchValue("");
        }
    };



    const handlePhoneChange = (value, country, e, formattedValue) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.slice(countryCode.length);

        setMobile(value);

        setCountryCode(countryCode);
        setNationalNumber(phoneNumber);
        setPhoneError("");
        // setMobile(val);
        // const phoneNumber = parsePhoneNumber(val);
        // const nationalNumber = phoneNumber ? phoneNumber.nationalNumber : "";
        // console.log("phn", phoneNumber);
        // console.log("nationalNumber", nationalNumber);
        // setNationalNumber(nationalNumber);
        // const countryCode = phoneNumber ? phoneNumber.countryCallingCode : "+91";
        // console.log("countrycode ", countryCode);
        // setCountryCode(countryCode);
        setPhoneTouched(true);
    };




    const bottomValidateFields = () => {
        let isValid = true;

        if (!houseNo) {
            setHouseNoError("House No. is required.");
            isValid = false;
        } else if (houseNo.length < 1 || houseNo.length > 1000) {
            setHouseNoError("House No. must be between 1 and 1000 characters.");
            isValid = false;
        } else {
            setHouseNoError("");
        }


        // Validate Address
        if (!address) {
            setAddressError("Address is required.");
            isValid = false;
        } else if (address.length < 3 || address.length > 1000) {
            setAddressError("Address must be between 3 and 1000 characters.");
            isValid = false;
        } else {
            setAddressError("");
        }


        if (!locality) {
            setLocalityError("locality is required.");
            isValid = false;
        } else if (locality.length < 3 || locality.length > 1000) {
            setLocalityError("Locality must be between 3 and 1000 characters.");
            isValid = false;
        } else {
            setLocalityError("");
        }

        // Validate State
        // if (!selectedStateId) {
        //   setStateError("State is required.");
        //   isValid = false;
        // } else {
        //   setStateError("");
        // }

        // Validate City
        if (!selectedCityId) {
            setCityError("City is required.");
            isValid = false;
        } else {
            setCityError("");
        }

        // Validate Pincode
        if (!pincode) {
            setPincodeError("Pincode is required.");
            isValid = false;
        } else if (!/^\d+$/.test(pincode)) {
            setPincodeError("Pincode must contain only digits.");
            isValid = false;
        } else if (pincode.length != 6) {
            setPincodeError("Pincode must be 6 digits.");
            isValid = false;
        } else {
            setPincodeError("");
        }

        if (otpError !== "") {
            setOtpError("invalid otp");
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let completeAddress = houseNo + ', ' + locality + ', ' + address;


        if (bottomValidateFields()) {
            try {
                const bodySend = {
                    name: fname,
                    phone_number: nationalNumber,
                    state_id: selectedStateId,
                    city_id: selectedCityId,
                    address_type: "other",
                    address: completeAddress,
                    pincode: pincode,
                    latitude: latitude,
                    longitude: longitude,
                };


                const saveAddressData = await saveAddress(bodySend);


                if (saveAddressData.status === "200") {
                    const existingData = JSON.parse(localStorage.getItem("userIdData"));

                    if (existingData) {
                        existingData.state = selectedState;
                        existingData.city = selectedCity;
                        existingData.address = address;
                        existingData.pincode = pincode;
                        existingData.selectedCityId = selectedCityId;
                        existingData.selectedStateId = selectedStateId;
                        localStorage.setItem("userIdData", JSON.stringify(existingData));
                    }
                    localStorage.setItem("loggedin", "true");

                    // window.location.href = "/timeSlot"
                    saveSlot();



                }
            } catch (error) {
                console.error("Error in Save Address:", error);
                Swal.fire({
                    title: "",
                    text: "Error on Save User",
                    icon: "error",
                });
            }
        }
    };

    const saveSlot = async () => {

        const existingData = JSON.parse(localStorage.getItem("petData"));
        const bookingPlanData = JSON.parse(localStorage.getItem("selectedPlan"));
        const homeData = JSON.parse(localStorage.getItem("homeData"));


        if (existingData) {
            setPetId(existingData.pet_id);
            existingData.selectedDate = date;
            existingData.selectedSlot = selectedTimeSlote;
            localStorage.setItem("petData", JSON.stringify(existingData));
        }



        if (TimeSlotDatas && TimeSlotDatas.length === 0) {
            setTimeSlotError("No Time Slotes Available. Please Select Another Date And Time Slot.");
        }
        else {

            if (selectedTimeSlote) {
                setTimeSlotError("");
                const titleParts = selectedTimeSlote.title.split("-");
                const firstPartOfTitle = titleParts[0].trim();

                if (fromWhich.fromPage === "bookedplan") {
                    const bodySend = {
                        order_id: fromWhich.id,
                        appoinment_date: date,
                        // appoinment_time: firstPartOfTitle,
                        appoinment_time: selectedTimeSlote.slug,
                    };

                    try {
                        const orderReshadule = await ReschaduleOrder(bodySend);

                        if (orderReshadule.success === "1") {
                            Swal.fire({
                                title: "Success",
                                text: "Rescheduled Successfully!!",
                                icon: "success",
                                showConfirmButton: false,
                                confirmButtonText: "OK",
                                willClose: (isConfirmed) => {
                                    if (isConfirmed) {
                                        navigate("/mybookings");
                                        setTimeout(() => {
                                            window.scrollTo(0, 0);
                                        }, 500);
                                    }
                                },
                                timer: 1000,
                                timerProgressBar: true,
                            }).then((confirm) => { });
                        } else {
                            Swal.fire({
                                title: "",
                                text: orderReshadule.message,
                                icon: "error",
                                showConfirmButton: true,
                                confirmButtonText: "OK",
                                willClose: (isConfirmed) => {
                                    if (isConfirmed) {
                                        navigate("/mybookings");
                                        setTimeout(() => {
                                            window.scrollTo(0, 0);
                                        }, 500);
                                    }
                                },
                                timer: 1000,
                                timerProgressBar: true,
                            }).then((confirm) => { });
                        }
                    } catch (error) {
                        console.log("Error on Reshadule Order", error);
                    }
                } else {
                    if (bookingPlanData.pet_type != existingData.pet_type || homeData.selectedCityId != selectedCityId) {
                        navigate('/package-confirmation')
                        window.scroll(0, 0);
                    } else {


                        const bodySend = {
                            item_id: bookingPlanData?.id,
                            // variation_id: selectedTimeSlote.id,
                            clear_cart: 0,
                            quantity: 1,
                            city_id: homeData.selectedCityId,
                            isMultipleCity: false,
                            appoinment_date: date,
                            appoinment_time: selectedTimeSlote.slug,
                            pet_id: existingData.pet_id,
                        };

                        try {
                            const cart = await addToCart(bodySend);
                            if (cart.status == 200 || cart.message == "Item already in cart") {
                                navigate("/myorder");
                                // setTimeout(() => {
                                //     window.scrollTo(0, 0);
                                // }, 500);
                            }
                        } catch (error) {
                            console.log("Error on Add to Cart", error);
                        }
                    }
                }
            } else {
                setTimeSlotError("Please Select Time Slot");
                Swal.fire({
                    title: "",
                    text: "Please Select Time Slot",
                    icon: "error",
                    showConfirmButton: true,
                    confirmButtonText: "OK",

                    timer: 1000,
                    timerProgressBar: true,
                });
            }
        }

    };


    useEffect(() => {
        const initializeTimeSlots = async () => {
            // setLoading(true);
            let timeSlots = await fetchTimeSlotsForDate(date, selectedCityId);
            if (timeSlots && timeSlots.length === 0) {
                // If no time slots available for the current date, find the next available date with time slots
                let nextDate = new Date(currentDate);
                while (timeSlots.length === 0) {
                    nextDate.setDate(nextDate.getDate() + 1);
                    const nextFormattedDate = formatDate(nextDate);
                    const nextTimeSlots = await fetchTimeSlotsForDate(nextFormattedDate, selectedCityId);
                    timeSlots = nextTimeSlots;
                    setDate(nextFormattedDate);
                }
            }
            setTimeSlotDatas(timeSlots);
            // setLoading(false);
        };

        initializeTimeSlots();
    }, []);

    const formatDate = (selectedDate) => {
        const year = selectedDate.getFullYear();
        const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
        const day = String(selectedDate.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    };

    const onChange = async (e) => {

        setTimeSlotError("");
        const existingData = JSON.parse(localStorage.getItem("petData"));


        setSelectedTimeSlote(existingData?.selectedSlot);

        if (existingData) {
            const selectedDate = "";
            const selectedSlot = "";

            existingData.selectedDate = selectedDate;
            existingData.selectedSlot = selectedSlot;

            localStorage.setItem("petData", JSON.stringify(existingData));
        }

        const selectedDate = new Date(e);
        // Format the date
        const formattedDate = formatDate(selectedDate);
        setDate(formattedDate);
        const timeSlots = await fetchTimeSlotsForDate(formattedDate, selectedCityId);
        setTimeSlotDatas(timeSlots);
    };


    useEffect(() => {
        if (authTokens === undefined) {
            const mytoken = localStorage.getItem("authToken");
            setAuthTokens(mytoken);
        }
    }, [authTokens]);

    const handleNavigation = () => {
        navigate("/login", { state: { fromRegister: "register" } });
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);
    };

    //set map location & Get
    const handleMapClick = (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status !== "OK" || !results[0]) {
                console.error("Geocode was not successful for the following reason:", status);
                return;
            }

            if (results[0]) {


                const pincode = results[0].address_components.find(component =>
                    component.types.includes("postal_code")
                )?.long_name || "";


                const selectedCityMap = cities.find(city =>
                    results[0].address_components.some(component =>
                        component.long_name.toLowerCase().includes(city.title.toLowerCase())
                    )
                );

                // Filter out city, state, and country components
                const filteredComponents = results[0].address_components.filter(component =>
                    !component.types.some(type =>
                        ["locality", "administrative_area_level_1", "country", "postal_code", "plus_code"].includes(type)
                    )
                );

                // Construct the address from the remaining components
                const filteredAddress = filteredComponents.map(component => component.long_name).join(', ');

                // Set the address state first
                setAddress(filteredAddress);


                if (selectedCityMap) {
                    setSelectedCity(selectedCityMap.title);
                    setSelectedCityId(selectedCityMap.id);
                }

                if (pincode) {
                    setPincode(pincode);
                }
            }
        });
    };






    useEffect(() => {
        if (googleAutocomplete) {



            const selectedCityMap = cities.find((city) =>
                googleAutocomplete.address_components.some((component) =>
                    component.long_name.toLowerCase().includes(city.title.toLowerCase())
                )
            );

            const pincodeComponent = googleAutocomplete.address_components.find((component) =>
                component.types.includes("postal_code")
            );
            const pincode = pincodeComponent ? pincodeComponent.long_name : "";

            const sublocalityComponents = googleAutocomplete.address_components.filter((component) =>
                component.types.includes("sublocality")
            );

            const sublocalities = sublocalityComponents.map((component) => component.long_name);

            const combinedSublocalities = sublocalities.join(', ');





            const filteredComponents = googleAutocomplete.address_components.filter((component) =>
                !component.types.some((type) =>
                    ["plus_code", "locality", "country", "postal_code"].includes(type)
                )
            );

            const filteredAddress = filteredComponents.map((component) => component.long_name).join(", ");

            setAddress(filteredAddress);
            setAddressError("");

            if (selectedCityMap) {

                setSelectedCity(selectedCityMap.title);
                setSelectedCityId(selectedCityMap.id);
            }
            if (pincode) {
                setPincode(pincode);
                setPincodeError("");
            }
            if (combinedSublocalities) {
                setLocality(combinedSublocalities);
            }
        }
    }, [cities, googleAutocomplete]);


    const formatWeekday = (locale, date) => {
        // Example: Return first letter of the weekday
        return date.toLocaleDateString(locale, { weekday: 'short' });
    };

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Select Date"
                className="calendar-modal"
                overlayClassName={'calendar-modal-overlay'}
                ariaHideApp={false}

            >
                <>
                    <Calendar
                        onChange={onChange}
                        min={formatDate(today)}
                        minDate={new Date()}
                        value={date}
                        formatWeekday={formatWeekday}
                    />
                    <div className="dividerBelow my-2"></div>
                    <div className="select-time-label">
                        <span>Select Time</span>
                    </div>
                    <div className="mb-3 px-3 sloteDiv justify-content-center align-items-center">
                        {Array.isArray(TimeSlotDatas) && TimeSlotDatas.length > 0 ? (
                            TimeSlotDatas.map((timeSlot) => (
                                <button
                                    key={timeSlot.id}
                                    type="button"
                                    className={`timeSlotBtn m-1 ${selectedTimeSlote && selectedTimeSlote.id === timeSlot.id ? "timeSloteActive" : ""} ${timeSlot.available ? "availableSlot" : "bookedSlot"}`}
                                    onClick={() => {
                                        setSelectedTimeSlote(timeSlot);
                                        setTimeSlotError("");
                                    }}
                                    disabled={!timeSlot.available}
                                >
                                    {timeSlot.title}
                                    <span className={`status ${timeSlot.available ? "available" : "booked"}`}>
                                        {timeSlot.available ? "Available" : "Booked"}
                                    </span>
                                </button>
                                // <input
                                //     key={timeSlot.id}
                                //     type="button"
                                //     className={`timeSlotBtn m-1 ${selectedTimeSlote && selectedTimeSlote.id === timeSlot.id
                                //         ? "timeSloteActive"
                                //         : ""
                                //         }`}
                                //     value={timeSlot.title}
                                //     onClick={() => {
                                //         if (timeSlot.available) {
                                //             setSelectedTimeSlote(timeSlot);
                                //             setTimeSlotError("");
                                //         }
                                //     }}
                                //     disabled={!timeSlot.available}
                                // />
                            ))
                        ) : (
                            <p className="mt-3">No Time Slotes Available. Please Select Another Date.</p>
                        )}

                    </div>
                    <button className="btn-save" onClick={() => setIsModalOpen(false)}>Save</button>
                </>
            </Modal>

            <div className="container my-5">
                <div className="row justify-content-center align-items-center px-2">
                    <div className="col-md-6 d-none d-lg-block">
                        <img src={LeftImg} alt="img" />
                    </div>
                    <div className="col-md-6 text-start formRightDiv">
                        <h5 className="text-center mt-4">
                            Address Details
                        </h5>
                        <form className="my-4">

                            <div className="mb-3 px-3 inputWrapper">
                                <label htmlFor="location" className="inputLabel">Your locality</label>
                                <ReactGoogleAutocomplete
                                    ref={autocompleteRef}
                                    apiKey="AIzaSyATEexg-rv1JsMhL7B46chlOv3wWzGrR7E"
                                    onPlaceSelected={(place) => {
                                        if (place.geometry) {
                                            setSelectedPlace(place);
                                            const lat = place.geometry.location.lat();
                                            const lng = place.geometry.location.lng();
                                            setLatitude(lat);
                                            setLongitude(lng);
                                            setGoogleAutocomplete(place);
                                            setInputSearchValue("");
                                            setAddressError("");

                                        }
                                    }}
                                    placeholder={'Search for location'}
                                    className="inputFormControl"
                                    options={{
                                        types: [],
                                        componentRestrictions: { country: "in" },
                                    }}
                                />

                                {addressError && (
                                    <div className="invalid-feedback">{addressError}</div>
                                )}
                            </div>

                            <div className="mb-3 px-3 inputWrapper">
                                <label htmlFor="houseno" className="inputLabel">Flat No./Budling</label>
                                <input
                                    type="text"
                                    className={`inputFormControl ${addressError ? "is-invalid" : ""}`}
                                    name="houseno"
                                    placeholder="Please add Flat, House no."
                                    onChange={(e) => {
                                        setHouseNo(e.target.value)
                                        setHouseNoError("");
                                    }}
                                    value={houseNo}
                                />
                                {houseNoError && (
                                    <div className="invalid-feedback">{houseNoError}</div>
                                )}
                            </div>

                            <div className="mb-3 px-3 inputWrapper">
                                <label htmlFor="locality" className="inputLabel">Locality/Street</label>
                                <input
                                    type="text"
                                    className={`inputFormControl ${localityError ? "is-invalid" : ""}`}
                                    name="locality"
                                    placeholder="Please add Locality, Street"
                                    onChange={(e) => {
                                        setLocality(e.target.value)
                                        setLocalityError("");
                                    }}
                                    value={locality}
                                />
                                {localityError && (
                                    <div className="invalid-feedback">{localityError}</div>
                                )}
                            </div>

                            <div className="mb-3 px-3 inputWrapper">
                                <label htmlFor="city" className="inputLabel">City</label>
                                <select
                                    className="inputFormControl"
                                    onChange={async (e) => {
                                        const selectedCityId = e.target.value;
                                        const selectedCity = cities.find(
                                            (city) => city.id === selectedCityId
                                        );

                                        setSelectedCityId(selectedCityId);
                                        setSelectedCity(selectedCity ? selectedCity.title : "");
                                        setCityError("");
                                        const timeSlots = await fetchTimeSlotsForDate(date, selectedCityId)
                                        setTimeSlotDatas(timeSlots);
                                    }}
                                    value={selectedCityId}

                                >
                                    <option value="" disabled hidden>
                                        {" "}
                                        Select City
                                    </option>
                                    {cities.map((city) => (
                                        <option key={city.id} value={city.id}>
                                            {city.title}
                                        </option>
                                    ))}
                                </select>
                                {cityError && <div className="invalid-feedback">{cityError}</div>}
                            </div>
                            <div className="mb-3 px-3 inputWrapper">
                                <label htmlFor="pincode" className="inputLabel">Pincode</label>
                                <input
                                    type="text"
                                    className={`inputFormControl ${pincodeError ? "is-invalid" : ""}`}
                                    name="pincode"
                                    placeholder="Pincode"
                                    onChange={(e) => {
                                        setPincode(e.target.value);
                                        setPincodeError("");
                                    }}
                                    value={pincode}
                                />
                                {pincodeError && (
                                    <div className="invalid-feedback">{pincodeError}</div>
                                )}
                            </div>

                            <h5 className="text-center my-4">
                                Appoinment Details
                            </h5>

                            <div className="mb-3 px-3 inputWrapper">
                                <label htmlFor="slots" className="inputLabel">Date and Time</label>
                                <input className="dateInputFormControl" id="slots" readOnly onClick={() => setIsModalOpen(true)} type="text" onChange={onChange} value={`${date && selectedTimeSlote?.title ? new Date(date).toLocaleDateString() : "Please Select Date and Time"} ${selectedTimeSlote?.title ? `• ${selectedTimeSlote.title}` : ''}`} min={formatDate(today)} />
                            </div>
                            {/* <div className="mb-3 px-3 sloteDiv justify-content-center align-items-center">
                            {Array.isArray(TimeSlotDatas) && TimeSlotDatas.length > 0 ? (
                                TimeSlotDatas.map((timeSlot) => (
                                    <button
                                        key={timeSlot.id}
                                        type="button"
                                        className={`timeSlotBtn m-1 ${selectedTimeSlote && selectedTimeSlote.id === timeSlot.id ? "timeSloteActive" : ""} ${timeSlot.available ? "availableSlot" : "bookedSlot"}`}
                                        onClick={() => {
                                            setSelectedTimeSlote(timeSlot);
                                            setTimeSlotError("");
                                        }}
                                        disabled={!timeSlot.available}
                                    >
                                        {timeSlot.title}
                                        <span className={`status ${timeSlot.available ? "available" : "booked"}`}>
                                            {timeSlot.available ? "Available" : "Booked"}
                                        </span>
                                    </button>
                                    // <input
                                    //     key={timeSlot.id}
                                    //     type="button"
                                    //     className={`timeSlotBtn m-1 ${selectedTimeSlote && selectedTimeSlote.id === timeSlot.id
                                    //         ? "timeSloteActive"
                                    //         : ""
                                    //         }`}
                                    //     value={timeSlot.title}
                                    //     onClick={() => {
                                    //         if (timeSlot.available) {
                                    //             setSelectedTimeSlote(timeSlot);
                                    //             setTimeSlotError("");
                                    //         }
                                    //     }}
                                    //     disabled={!timeSlot.available}
                                    // />
                                ))
                            ) : (
                                <p className="mt-3">No Time Slotes Available. Please Select Another Date.</p>
                            )}
                            {timeSlotError && (
                                <div className="mt-3 invalid-feedback justify-content-center">
                                    {timeSlotError}
                                </div>
                            )}
                        </div> */}
                            {timeSlotError && (
                                <div className="mt-3 invalid-feedback justify-content-center">
                                    {timeSlotError}
                                </div>
                            )}

                            <div className="mb-3 px-3 text-center">
                                <button
                                    type="submit"
                                    className={
                                        "btn nextBtn text-center "
                                    }
                                    onClick={handleSubmit}
                                >
                                    Next
                                </button>

                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </>
    );
};

export default AddressDetails;
