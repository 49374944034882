import React from "react";
import ExprtiseImg from "../../assets/images/expertise.svg";
import TrustImg from "../../assets/images/trust.svg";
import QualityImg from "../../assets/images/quality.svg";
import ConvenienceImg from "../../assets/images/convenience.svg";
import CommitmentImg from "../../assets/images/commitment.svg";
import ShampooImg from "../../assets/images/shampoo-new.svg";
import OrangeSplash from "../../assets/icons/orange-splash.svg";
import "./style.css";

const WhyChooes = () => {
  return (
    <div>

    <h3 className="whyChooesHeading mb-0">Why Choose <span style={{ color: '#F29A4C' }}>DearPet?</span></h3>
    <div className="why-choose-wrapper-container">
      
      <div className="why-choose-wrapper">

        <div className="shampoo-container">
          <img src={ShampooImg} alt="img" className="shampoImg" />
        </div>
        <div className="why-choose-container">
        <div className="shampoo-container-splash">
          <img src={OrangeSplash} alt="img" className="orangeSplash" />
        </div>
          <div className="why-text-container">
            <div className="WC-row">
              <div className="leftIconImg me-4">
                <img className="img" src={ExprtiseImg} alt="iconimg" />
              </div>
              <div className="rightContent">
                <h5 className="m-0 WR-Top">Expertise :</h5>
                <p className="m-0 WR-Bottom">20 years in the pet care industry</p>
              </div>
            </div>
            <div className="WC-row">
              <div className="leftIconImg me-4">
                <img className="img" src={QualityImg} alt="iconimg" />
              </div>
              <div className="rightContent">
                <h5 className="m-0 WR-Top">Quality :</h5>
                <p className="m-0 WR-Bottom">
                  <a
                    className="whychooesItemLink"
                    href="https://bit.ly/3XR36c1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    4.9/5 star rating on Google
                  </a>
                </p>
              </div>
            </div>
            <div className="WC-row">
              <div className="leftIconImg me-4">
                <img className="img" src={TrustImg} alt="iconimg" />
              </div>
              <div className="rightContent">
                <h5 className="m-0 WR-Top">Trust :</h5>
                <p className="m-0 WR-Bottom">
                  Rigorous background checks and training for all professionals
                </p>
              </div>
            </div>
            <div className="WC-row">
              <div className="leftIconImg me-4">
                <img className="img" src={ConvenienceImg} alt="iconimg" />
              </div>
              <div className="rightContent">
                <h5 className="m-0 WR-Top">Convenience :</h5>
                <p className="m-0 WR-Bottom">
                  At-home services tailored to your pet's needs
                </p>
              </div>
            </div>
            <div className="WC-row">
              <div className="leftIconImg me-4">
                <img className="img" src={CommitmentImg} alt="iconimg" />
              </div>
              <div className="rightContent">
                <h5 className="m-0 WR-Top">Commitment :</h5>
                <p className="m-0 WR-Bottom">Over 10,000 pets groomed and counting</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="shampoo-container">
          <img src={ShampooImg} alt="img" className="shampoImg" />
        </div> */}
      </div>
    </div>
    </div>

  );
};

export default WhyChooes;