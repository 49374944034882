import React, { useEffect, useState } from "react";
// import "./style.css";
import LeftImg from "../../assets/images/ProfileLeftImg.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAddress,
  getCities,
  getProfile,
  getStates,
  saveAddress,
  updateProfile,
} from "../../service/api";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import MapComponent from "../PersonalDetails/MapComponent";
const Profile = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const fromPage = location.state?.frompage || "";

  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [fname, setFname] = useState("");
  // const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCityId, setSelectedCityId] = useState();
  const [selectedStateId, setSelectedStateId] = useState();

  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [addressId, setAddressId] = useState();

  const [fnameError, setFNameError] = useState("");
  const [lnameError, setLNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [pincodeError, setPincodeError] = useState("");

  useEffect(() => {
    getUserProfille();
    fetchCities();
    getUserAddress();
    fetchStates();
  }, []);

  const fetchCities = async () => {
    try {
      const cityData = await getCities();
      const sortedCities = cityData.data.sort((a, b) => a.sorting - b.sorting);

      let addselectCity = [{ id: '-1', title: 'Select City', state_id: 13, sorting: '0' }, ...sortedCities]

      // setCities(sortedCities);
      setCities(addselectCity);
      // setCities(cityData.data);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const fetchStates = async () => {
    try {
      const stateData = await getStates();
      setStates(stateData.data);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const getUserAddress = async () => {
    try {
      const addressData = await getAddress();
      if (addressData.data.length !== 0) {
        setSelectedStateId(addressData.data[0].state_id);
        setSelectedCityId(addressData.data[0].city_id);
        setSelectedCity(addressData.data[0].city_name);
        setAddress(addressData.data[0].address);
        setPincode(addressData.data[0].pincode);
        setAddressId(addressData.data[0].id);
        setLatitude(addressData.data[0].latitude ? addressData.data[0].latitude : '23.022505');
        setLongitude(addressData.data[0].longitude ? addressData.data[0].longitude : '72.5713621');
      }
    } catch (error) {
      console.log("Error on fetching Address", error);
    }
  };

  const getUserProfille = async () => {
    try {
      const getUData = await getProfile();
      if (getUData.data) {
        const fullName = getUData.data.name;
        // const [firstName, lastName] = fullName.split(" ");

        setFname(fullName);
        // setLname(lastName);
        setEmail(getUData.data.email);
        setMobile(getUData.data.phone_number);
        setCountryCode(getUData.data.country_code);
        setGender(getUData.data.gender);
        setDob(getUData.data.dob);

      }
    } catch (error) {
      console.log("Error on get Profile", error);
    }
  };

  const validateFields = () => {
    let isValid = true;
    // Validate Name
    if (!fname) {
      setFNameError(" First Name is required.");
      isValid = false;
    } else if (fname.length < 2 || fname.length > 99) {
      setFNameError("Name must be between 3 and 99 characters.");
      isValid = false;
    } else {
      setFNameError("");
    }

    // if (!lname) {
    //   setLNameError("Last Name is required.");
    //   isValid = false;
    // } else if (lname.length < 2 || lname.length > 99) {
    //   setLNameError("Name must be between 3 and 99 characters.");
    //   isValid = false;
    // } else {
    //   setLNameError("");
    // }

    // Validate Email
    if (!email) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError("Invalid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Validate Address
    if (!address) {
      setAddressError("Address is required.");
      isValid = false;
    } else if (address.length < 3 || address.length > 1000) {
      setAddressError("Address must be between 3 and 1000 characters.");
      isValid = false;
    } else {
      setAddressError("");
    }

    // Validate City
    if (!selectedCityId) {
      setCityError("City is required.");
      isValid = false;
    } else {
      setCityError("");
    }

    // Validate Pincode
    if (!pincode) {
      setPincodeError("Pincode is required.");
      isValid = false;
    } else if (!/^\d+$/.test(pincode)) {
      setPincodeError("Pincode must contain only digits.");
      isValid = false;
    } else if (pincode.length < 3 || pincode.length > 10) {
      setPincodeError("Pincode must be between 3 and 10 characters.");
      isValid = false;
    } else {
      setPincodeError("");
    }

    return isValid;
  };

  const onUpdateProfile = async (e) => {

    e.preventDefault();

    if (validateFields()) {
      const profileBody = {
        name: fname,
        email: email,
        country_code: countryCode,
        phone_number: mobile,
        gender: gender,
        dob: dob,
      };

      const updateProf = await updateProfile(profileBody);

      let addressBody;
      if (addressId) {
        addressBody = {
          name: fname,
          phone_number: mobile,
          state_id: selectedStateId,
          city_id: selectedCityId,
          address_type: "other",
          address: address,
          pincode: pincode,
          latitude: latitude ?? '72.5713621',
          longitude: longitude ?? '23.022505',
          address_id: addressId,
        };
      } else {
        addressBody = {
          name: fname,
          phone_number: mobile,
          state_id: selectedStateId,
          city_id: selectedCityId,
          address_type: "other",
          address: address,
          pincode: pincode,
          latitude: latitude ?? '72.5713621',
          longitude: longitude ?? '23.022505',
        };
      }

      const addressProf = await saveAddress(addressBody);

      if (updateProf.success === "1" && addressProf.success === "1") {
        Swal.fire({
          title: "Success",
          text: "Profile Update Successfully",
          icon: "success",
          showConfirmButton: false,
          confirmButtonText: "OK",
          willClose: (isConfirmed) => {
            if (isConfirmed) {
              if (fromPage === "confirm") {
                navigate("/mypets");
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 500);
              } else {
                navigate("/");
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 500);
              }
            }
          },
          timer: 1000,
          timerProgressBar: true,
        }).then((confirm) => {

        });
      }
    }
  };
  const handleMapClick = (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
    // You can perform any additional actions with the clicked coordinates
  };

  return (
    <div className="container my-1">
      <div className="row justify-content-center align-items-center px-4">
        <div className="col-md-6 d-none d-lg-block">
          <img src={LeftImg} alt="img" />
        </div>
        <div className="col-md-6 text-start formRightDiv">
          <h5 className="text-center mt-4">Profile</h5>
          <form className="my-4">
            <div className="mb-3 px-3">
              
                <input
                  type="text"
                  className={`form-control ${fnameError ? "is-invalid" : ""}`}
                  name="name"
                  placeholder="Full Name"
                  onChange={(e) => {
                    setFname(e.target.value);
                    setFNameError("");
                  }}
                  value={fname}
                />
                {fnameError && (
                  <div className="invalid-feedback">{fnameError}</div>
                )}
              

              {/* <div className="form-check wHalf">
                <input
                  type="text"
                  className={`form-control ${lnameError ? "is-invalid" : ""}`}
                  name="lname"
                  placeholder="Last Name"
                  onChange={(e) => {
                    setLname(e.target.value);
                    setLNameError("");
                  }}
                  value={lname}
                />
                {lnameError && (
                  <div className="invalid-feedback">{lnameError}</div>
                )}
              </div> */}
            </div>
            <div className="mb-3 px-3 ">
              <input
                type="email"
                className={`form-control ${emailError ? "is-invalid" : ""}`}
                name="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError("");
                }}
                value={email}
              />
              {emailError && (
                <div className="invalid-feedback">{emailError}</div>
              )}
            </div>

            <div className="mb-3 px-3 phone-input-wrapper">
              <input
                type="text"
                className={`form-control ${phoneError ? "is-invalid" : ""}`}
                name="phone"
                placeholder=""
                onChange={(e) => {
                  setMobile(e.target.value);
                  setPhoneError("");
                }}
                value={mobile}
                disabled
              />
              {/* {phoneError && (
                <div className="invalid-feedback">{phoneError}</div>
              )} */}
            </div>

            <div className="mb-3 px-3 ">
              <select
                className="select-dropdown"
                onChange={(e) => {
                  const selectedCityId = e.target.value;
                  const selectedCity = cities.find(
                    (city) => city.id === selectedCityId
                  );

                  setSelectedCityId(selectedCityId);
                  setSelectedCity(selectedCity ? selectedCity.title : "");
                  setSelectedStateId(selectedCity ? selectedCity.state_id : 0);
                  setCityError("");
                }}
                value={selectedCityId}
              >
                <option value="" disabled hidden>
                  {" "}
                  Select City
                </option>
                {cities?.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.title}
                  </option>
                ))}
              </select>
              {cityError && <div className="invalid-feedback">{cityError}</div>}
            </div>
            <div className="mb-3 px-3 ">
              <input
                type="text"
                className={`form-control ${addressError ? "is-invalid" : ""}`}
                name="adsress"
                placeholder="Address"
                onChange={(e) => {
                  setAddress(e.target.value);
                  setAddressError("");
                }}
                value={address}
              />
              {addressError && (
                <div className="invalid-feedback">{addressError}</div>
              )}
            </div>
            <div className="mb-3 px-3 ">
              <input
                type="text"
                className={`form-control ${pincodeError ? "is-invalid" : ""}`}
                name="pincode"
                placeholder="Pincode"
                onChange={(e) => {
                  setPincode(e.target.value);
                  setPincodeError("");
                }}
                value={pincode}
              />
              {pincodeError && (
                <div className="invalid-feedback">{pincodeError}</div>
              )}
            </div>
            <div className="mb-3 px-3">
              {/* <MapComponent
                onMapClick={handleMapClick} // Make sure to provide onMapClick
              /> */}
            </div>
            <div className="mb-3 px-3 text-center">
              <button
                type="submit"
                className={"btn nextBtn text-center "}
                onClick={onUpdateProfile}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
