import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCities, getDashboard } from '../../service/api';
import CoverPhoto from '../Home/CoverPhoto';
import HowWorks from '../Home/HowWorks';
import WhyChooes from '../Home/WhyChooes';
import VideoSlider from '../Home/VideoSlider';
import Review from '../Home/Review';
import Gallery from '../Home/Gallery';
import StarGroomer from '../Home/StarGroomer';
import DearpetNurturing from '../Home/DearpetNurturing';
import FAQ from '../Home/FAQ';
import Packages from './Packages';
import dogSleep from '../../assets/images/dog-sleeping.png';
import PetGroomingService from './PetGroomingService';



export default function Grooming() {
  const { city } = useParams();
  const selectCityRef = useRef(null);
  const [cities, setCities] = useState([]);
  const [isValidCity, setIsValidCity] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [reviewData, setReviewData] = useState([]);
  const [matchedCity, setMatchedCity] = useState(null);


  const handleCoverPhotoClick = () => {
    selectCityRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    dashboardData();
  }, []);

  const dashboardData = async () => {
    try {
      const dashboardData = await getDashboard();
      if(dashboardData.status === '200'){
       
        setReviewData(dashboardData.data.reviews);
      }
    } catch (error) {
      console.error("Error in get home page data:", error);
    }
  };

  const fetchCities = async () => {
    try {
      const cityData = await getCities();
      setCities(cityData.data);
  
      let matchedCity = cityData.data.find(
        (c) => {
          if (city.toLowerCase() === 'delhi-ncr' && c.title.toLowerCase() === 'delhi') {
            return true;
          }
          return c.title.toLowerCase() === city.toLowerCase();
        }
      );
  
      if (matchedCity) {
        if (city.toLowerCase() === 'delhi-ncr') {
          matchedCity.title = 'Delhi-NCR'; 
          matchedCity.id = 3; 
        }
        setMatchedCity(matchedCity);
        setIsValidCity(true);
      } else {
        setIsValidCity(false);
      }
    } catch (e) {
      console.error('Error fetching cities', e);
    } finally {
      setIsLoading(false);
    }
  };

  // const fetchCities = async () => {
  //   try {
  //     const cityData = await getCities();
  //     console.log(cityData)
  //     setCities(cityData.data);

  //     const matchedCities = cityData.data.find(
  //       (c) => c.title.toLowerCase() === city.toLowerCase()
  //     );
  //     console.log(matchedCities);
  //     if (matchedCities) {
  //       setMatchedCity(matchedCities);
  //       setIsValidCity(true);
  //     } else {
  //       setIsValidCity(false);
  //     }

  //   } catch (e) {
  //     console.error('Error fetching cities', e);
  //   } finally {
  //     setIsLoading(false);
  //     console.log(matchedCity)
  //   }
  // };

  useEffect(() => {
    if(city){
        fetchCities();
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isValidCity) {
    return (
      <div style={{ display: 'flex', flex:1,  flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: "90vh" }}>
        <img src={dogSleep} alt="Dog Sleeping" style={{ maxWidth: '30%', height: 'auto', margin: 0 }} />
        <h1>Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    );
  }


  return (
    <>
    <CoverPhoto onCoverPhotoClick={handleCoverPhotoClick} city={matchedCity?.title} />
    <HowWorks />
    <div ref={selectCityRef}>

    <Packages cityId={matchedCity?.id} cityName={matchedCity?.title} />
    </div>
    <WhyChooes />
    <VideoSlider />
    <Review reviewData={reviewData} />
    <Gallery />
    <FAQ />
    <PetGroomingService city={matchedCity?.title} />
  </>
  );
}

