import React, { useEffect, useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";

import coverphoto from "../../assets/images/cover_photo.jpeg";

import CoverPhotoMob from "../../assets/covers/Phone_Website_1.png";
import CoverPhotoAll from "../../assets/covers/cover-all-pc.png";
import CoverPhotoDelhi from "../../assets/covers/cover-del-pc.png";
import CoverPhotoHyd from "../../assets/covers/cover-hyd-pc.png";
import CoverPhotoMum from "../../assets/covers/cover-mum-pc.png";
import CoverPhotoBang from "../../assets/covers/cover-bang-pc.png";

function CoverPhoto({ onCoverPhotoClick, city, handleBookNow, plan }) {
  // const [isMobile, setIsMobile] = useState(false);
  // useEffect(() => {
  //   const updateWindowDimensions = () => {
  //     setIsMobile(window.innerWidth < 768);
  //   };
  //   updateWindowDimensions();
  //   window.addEventListener("resize", updateWindowDimensions);
  //   return () => window.removeEventListener("resize", updateWindowDimensions);
  // }, []);

  // const getCoverPhoto = () => {
  //   if (isMobile) {
  //     return CoverPhotoMob;
  //   }
  //   return CoverPhotoAll;
  //   // if (city.toLowerCase().includes('delhi')) {
  //   //   return CoverPhotoDelhi;
  //   // } else if (city.toLowerCase().includes('hyderabad')) {
  //   //   return CoverPhotoHyd;
  //   // } else if (city.toLowerCase().includes('mumbai')) {
  //   //   return CoverPhotoMum;
  //   // } else if (city.toLowerCase().includes('bangalore')) {
  //   //   return CoverPhotoBang;
  //   // } else {
  //   //   return CoverPhotoAll;
  //   // }
  // };

  return (
    <div className="cover-photo-container" onClick={onCoverPhotoClick}>
      <img src={coverphoto} alt="Cover" className="cover-photo" />

      <div className="cover-photo-text-container">
        <div className="cover-heading-text">
          <h1 className="cover-photo-text-cur">Premium At-Home</h1>
          <span className="cover-photo-text-cur">Pet Grooming</span>
        </div>

        {/* <span className="cover-photo-btn">Book Appointment</span> */}

        <Link
          className="cover-photo-btn"
          to={"/mypets"}
          onClick={() => handleBookNow(plan)}
        >
          Book Appointment
        </Link>
      </div>
    </div>
  );
}

export default CoverPhoto;
