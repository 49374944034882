import React, { useEffect, useState } from "react";
import { PurchaseHistory } from "../../service/api";
import { ThreeDots } from "react-loader-spinner";
import "./style.css";
import { useNavigate } from "react-router-dom";
import PeparIcon from "../../assets/icons/Paper.svg";
import CalenderIcon from "../../assets/icons/Calendar.svg";
import WatchIcon from "../../assets/icons/Time Circle-gray.svg";
import BokingStatusImg from "../../assets/images/amico.svg";
import ReactPixel from 'react-facebook-pixel';

const MyBookings = () => {
  const navigate = useNavigate();
  const [orderHistory, setOrderHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const options = {
      autoConfig: true,
      debug: true,
    };
    ReactPixel.init('258887642266418', "", options);
    ReactPixel.pageView("My Booking");
    ReactPixel.track('CompleteRegistration');
    getOrderHistory();
  }, []);

  const getOrderHistory = async () => {
    try {
      const orderData = await PurchaseHistory();
      if (orderData.data) {
        setOrderHistory(orderData.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error on get OrderHistory", error);
    }
  };

  const formatDateTime = (dateTimeString) => {
    const [date, time] = dateTimeString.split(' ');
    return { bookingDate: date, bookingTime: time };
  };

  const moveBookingItem = (id) => {
    navigate("/bookedplan", { state: { bookingID: id } });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const gotohome = () => {
    navigate("/");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  return (
    <div className="container mb-5 bookingContainer mt-4">
      <div className="row mt-4 px-3">
        <div className="col-md-12 mainRow pt-4">
          <p className="text-center bookingHeading">Your Booking</p>
          {isLoading && (
            <div className="d-flex justify-content-center">
              <ThreeDots color="#7B5BFB" height={50} width={50} />
            </div>
          )}
          {!isLoading && orderHistory && (
            <div className="card-container">
              {orderHistory.length === 0 ? (
                <p className="text-center mt-5 notFoundText">
                  No bookings Found.
                </p>
              ) : (
                orderHistory.map((order) => (
                  <div
                    key={order.id}
                    className="card my-3"
                    onClick={() => moveBookingItem(order.id)}
                  >
                    <div className="status-div mb-2">
                      <img src={BokingStatusImg} alt="img" className="me-1" />
                      <p className={`order-status m-0 ${order.status.toLowerCase().replace(/ /g, '-')}`}>
                        {order.status_lable}
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="item-title m-0">Booking Details</p>

                      <div className="book-flex">
                        <p className="item-d-text m-0">
                          <img className="b-icon" src={PeparIcon} alt="icon" /> Booking ID:
                        </p>
                        <span className="item-d-value">
                          {order.custom_order_id}
                        </span>
                      </div>

                      <div className="book-flex">
                        <p className="item-d-text m-0">
                          <img className="b-icon" src={CalenderIcon} alt="icon" /> Booking Date:
                        </p>
                        <span className="item-d-value">
                          {formatDateTime(order.created_at).bookingDate}
                        </span>
                      </div>

                      <div className="book-flex">
                        <p className="item-d-text m-0">
                          <img className="b-icon" src={CalenderIcon} alt="icon" /> Booking Time:
                        </p>
                        <span className="item-d-value">
                          {formatDateTime(order.created_at).bookingTime}
                        </span>
                      </div>

                      <div className="book-flex">
                        <p className="item-d-text m-0">
                          <img className="b-icon" src={WatchIcon} alt="icon" /> OTP:
                        </p>
                        <span className="item-d-value">{order.otp}</span>
                      </div>

                      <hr />
                      <div className="order-items oi-2">
                        <p className="item-title m-0">Items in Booking</p>
                        <p className="item-d-text m-0 ms-1">
                          {order.items[0].title}
                        </p>
                      </div>
                      <div className="order-items oi-2">
                        <p className="item-title m-0">Service for</p>
                        <p className="item-d-text m-0 ms-1">
                          {order.pet_name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>

        <div className="my-3 px-3 text-center">
          <button
            type="submit"
            className="btn nextBtnH text-center"
            onClick={gotohome}
          >
            Go To Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyBookings;
