import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function useCaptureUTMParameters() {
  const query = useQuery()
  useEffect(() => {
    const allParams = {};
    const utm_id= query.get('utm_id');
    const utmSource = query.get('utm_source');
    const utmMedium = query.get('utm_medium');
    const utmCampaign = query.get('utm_campaign');
    const utmContent = query.get('utm_content');
    const utmTerm = query.get('utm_term');
    console.log(utm_id,utmSource , utmMedium, utmCampaign,utmContent,utmTerm);
    
    query.forEach((value, key) => {
      allParams[key] = value;
    });
    
      localStorage.setItem('utm_content', utm_id);
      localStorage.setItem('utm_source', utmSource);
      localStorage.setItem('utm_medium', utmMedium);
      localStorage.setItem('utm_campaign', utmCampaign);
      localStorage.setItem('utm_content', utmContent);
      localStorage.setItem('utm_term', utmTerm);
      localStorage.setItem('utm_all', JSON.stringify(allParams));
  }, []);
}


export default useCaptureUTMParameters;