import React, { useEffect, useState } from "react";
import "./style.css";
import LeftImg from "../../assets/images/Mobile login-bro.svg";
import { getAddress, getCities, saveAddress } from "../../service/api";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const Address = () => {
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const [loggedUserData, setLoggedUserData] = useState();

  const [uAddress, setUAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityId, setSelectedCityId] = useState();
  const [selectedStateId, setSelectedStateId] = useState();
  const [addressId, setAddressId] = useState();

  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [pincodeError, setPincodeError] = useState("");

  const [navDestination, setNavDestination] = useState("");

  useEffect(() => {
    fetchCities();
    getUserAddress();
  }, []);

  useEffect(() => {
    const loginUserData = JSON.parse(localStorage.getItem("userIdData"));
    const callFrom = JSON.parse(localStorage.getItem("addPageCall"));
    if (loginUserData) {
      setLoggedUserData(loginUserData);
    }
    if (callFrom) {
      setNavDestination(callFrom);
    }
  }, []);

  const fetchCities = async () => {
    try {
      const cityData = await getCities();
      setCities(cityData.data);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const getUserAddress = async () => {
    try {
      const addressData = await getAddress();
      if (addressData.data.length > 0) {
        setSelectedStateId(addressData.data[0].state_id);
        setSelectedCityId(addressData.data[0].city_id);
        setUAddress(addressData.data[0].address);
        setPincode(addressData.data[0].pincode);
        setAddressId(addressData.data[0].id);
      }
    } catch (error) {
      console.log("Error on fetch Address", error);
    }
  };

  const addressValidation = () => {
    let isValid = true;

    // Validate Address
    if (!uAddress) {
      setAddressError("Address is required.");
      isValid = false;
    } else if (uAddress.length < 3 || uAddress.length > 1000) {
      setAddressError("Address must be between 3 and 1000 characters.");
      isValid = false;
    } else {
      setAddressError("");
    }

    // Validate City
    if (!selectedCityId) {
      setCityError("City is required.");
      isValid = false;
    } else {
      setCityError("");
    }

    // Validate Pincode
    if (!pincode) {
      setPincodeError("Pincode is required.");
      isValid = false;
    } else if (!/^\d+$/.test(pincode)) {
      setPincodeError("Pincode must contain only digits.");
      isValid = false;
    } else if (pincode.length < 3 || pincode.length > 10) {
      setPincodeError("Pincode must be between 3 and 10 characters.");
      isValid = false;
    } else {
      setPincodeError("");
    }

    return isValid;
  };

  const onSaveAddress = async (e) => {
    e.preventDefault();

    if (addressValidation()) {
      try {
        let bodySend;
        if (addressId) {
          bodySend = {
            name: loggedUserData.name,
            phone_number: loggedUserData.phone_number,
            state_id: selectedStateId,
            city_id: selectedCityId,
            address_type: "other",
            address: uAddress,
            pincode: pincode,
            latitude: "12547.254",
            longitude: "487458745",
            address_id: addressId,
          };
        } else {
          bodySend = {
            name: loggedUserData.name,
            phone_number: loggedUserData.phone_number,
            state_id: selectedStateId,
            city_id: selectedCityId,
            address_type: "other",
            address: uAddress,
            pincode: pincode,
            latitude: "12547.254",
            longitude: "487458745",
          };
        }
        const address = await saveAddress(bodySend);

        if (address.success === "1") {
          swal({
            title: "Success",
            text: "Address Save Successfully",
            icon: "success",
          }).then((confirm) => {
            if (confirm) {
              if (navDestination === "register") {
                navigate("/addpet");
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 500);
              } else {
                navigate("/");
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 500);
              }
            } else {
              setTimeout(() => {
                if (navDestination === "register") {
                  navigate("/addpet");
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 500);
                } else {
                  navigate("/");
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 500);
                }
              }, 1000);
            }
          });
        }
      } catch (error) {
        console.log("Error on Saving Address", error);
      }
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-md-6">
          <img src={LeftImg} alt="img" />
        </div>
        <div className="col-md-6 text-center">
          <h5 className="text-center registerTitle">Address</h5>
          <form className="my-4">
            <div className="mb-3 px-3 ">
              <select
                className="form-control select-dropdown"
                onChange={(e) => {
                  const selectedCityId = e.target.value;
                  const selectedCity = cities.find(
                    (city) => city.id === selectedCityId
                  );

                  setSelectedCityId(selectedCityId);
                  setSelectedCity(selectedCity ? selectedCity.title : "");
                  setSelectedStateId(selectedCity ? selectedCity.state_id : "");
                  setCityError("");
                }}
                value={selectedCityId}
              >
                <option value="" disabled hidden>
                  {" "}
                  Select City
                </option>
                {cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.title}
                  </option>
                ))}
              </select>
              {cityError && <div className="invalid-feedback">{cityError}</div>}
            </div>
            <div className="mb-3 px-3 ">
              <input
                type="text"
                className={`form-control ${addressError ? "is-invalid" : ""}`}
                name="adsress"
                placeholder="Address"
                onChange={(e) => {
                  setUAddress(e.target.value);
                  setAddressError("");
                }}
                value={uAddress}
              />
              {addressError && (
                <div className="invalid-feedback">{addressError}</div>
              )}
            </div>
            <div className="mb-3 px-3 ">
              <input
                type="text"
                className={`form-control ${pincodeError ? "is-invalid" : ""}`}
                name="pincode"
                placeholder="Pincode"
                onChange={(e) => {
                  setPincode(e.target.value);
                  setPincodeError("");
                }}
                value={pincode}
              />
              {pincodeError && (
                <div className="invalid-feedback">{pincodeError}</div>
              )}
            </div>
            <div className="mb-3 px-3 text-center">
              <button
                type="submit"
                className={"btn nextBtn text-center "}
                onClick={onSaveAddress}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Address;
