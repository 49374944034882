// import React, { useEffect, useState } from "react";
// import "./style.css";
// import LeftImg from "../../assets/images/Personal site-amico.svg";
// // import PhoneInput from "react-phone-number-input";
// // import { parsePhoneNumber } from "react-phone-number-input";
// // import "react-phone-number-input/style.css";
// import OtpInput from "react-otp-input";
// import dogIocn from "../../assets/icons/mdi-dog.svg";
// import catIocn from "../../assets/icons/mdi-cat.svg";
// import dogDarkIocn from "../../assets/icons/mdi-dark-dog.svg";
// import catDarkIocn from "../../assets/icons/mdi-dark-cat.svg";
// import { useNavigate } from "react-router-dom";
// import DogImage from "../../assets/images/d-s-dog.png";
// import CatImage from "../../assets/images/d-s-cat.png";
// import MapComponent from "./MapComponent";
// import SinglePawIcon from '../../assets/icons/single-paw.svg';
// import PetBackground from '../../assets/icons/plans-doodle.svg'
// import {
//   activeAccount,
//   getCities,
//   getStates,
//   loginWithOtp,
//   saveAddress,
//   sendOtp,
//   userDetails, addPet, getBreedList
// } from "../../service/api";
// import { Dropdown } from "react-bootstrap";

// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import Swal from "sweetalert2";
// import "sweetalert2/dist/sweetalert2.min.css";

// import { setAuthToken } from "./../../service/apiService";
// import ReactGoogleAutocomplete from "react-google-autocomplete";


// const BreedDropdown = ({ breedOptions, selectedBreed, onBreedChange }) => {
//   const [searchTerm, setSearchTerm] = useState("");
//   const [selectedBreedName, setSelectedBreedName] = useState("");
//   const [dropdownWidth, setDropdownWidth] = useState(null);

//   const filteredBreeds = breedOptions.filter((breed) =>
//     breed.title.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const handleBreedSelect = (breedId, breedTitle) => {

//     setSelectedBreedName(breedTitle);
//     onBreedChange(breedId);
//   };

//   return (
//     <div className="breed-drop px-3">

//       <Dropdown>
//         <Dropdown.Toggle variant="primary" id="dropdown-basic">
//           {selectedBreedName ? selectedBreedName : "Select Breed"}
//         </Dropdown.Toggle>
//         <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "scroll" }}>
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Search..."
//             value={searchTerm}
//             onChange={handleSearchChange}
//           />
//           {filteredBreeds.map((breed) => (
//             <Dropdown.Item
//               key={breed.id}
//               onClick={() => handleBreedSelect(breed.id, breed.title)}
//             >
//             {breed.title}
//             </Dropdown.Item>
//           ))}
          
//         </Dropdown.Menu>
//         <div className="dropdown-menu-prepend">
//           <div className="dropdown-toggle-append" />
//         </div>
//       </Dropdown>
//     </div>
//   );
// };

// const PersonalDetailsData = () => {
//   const navigate = useNavigate();

//   const [otpState, setOtpState] = useState(false);
//   const [otp, setOtp] = useState("");


//   const [fname, setFname] = useState("");

//   const [email, setEmail] = useState("");
//   const [countryCode, setCountryCode] = useState("+91");
//   const [mobile, setMobile] = useState("");
//   const [verified, setVerified] = useState(false);
//   const [nationalNumber, setNationalNumber] = useState();

//   const [allUData, setAllUData] = useState([]);

//   const [fnameError, setFNameError] = useState("");

//   const [emailError, setEmailError] = useState("");
//   const [phoneError, setPhoneError] = useState("");

//   const [otpError, setOtpError] = useState("");
//   const [phoneTouched, setPhoneTouched] = useState(false);

//   const [otpBtnState, setOtpBtnState] = useState(false);
//   const [loginApiCall, setLoginApiCall] = useState();
//   const [otpSendSts, setOtpSendSts] = useState(false);

//   const [authTokens, setAuthTokens] = useState();
//   const [googleAutocomplete, setGoogleAutocomplete] = useState();

//   const [pet_name, setPet_name] = useState("");
//   const [pet_type, setPet_type] = useState("");
//   const [breed, setBreed] = useState("");
//   const [breedName, setBreedName] = useState("");
//   const [suggestions, setSuggestions] = useState([]);
//   const [showSuggestions, setShowSuggestions] = useState(false);
//   const [breedOptions, setBreedOptions] = useState([]);
//   const [dob, setDob] = useState("");
//   const [petNameError, setPetNameError] = useState("");
//   const [petTypeError, setPetTypeError] = useState("");
//   const [breedError, setBreedError] = useState("");
//   const [aggressionError, setAggressionError] = useState("");
//   const [aggression, setAggression] = useState("");
  

//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'instant'
//     });
//   },[])

//   useEffect(() => {
//     const homeData = JSON.parse(localStorage.getItem("homeData"));
//     if (homeData) {
//       setPet_type(homeData.selectedPet);
//     }

//     const petData = JSON.parse(localStorage.getItem("petData"));
//     if (petData) {
//       setPet_name(petData.pet_name);
//       setBreed(petData.breed);

//     }

//     getBreeds();
//   }, []);

//   useEffect(() => {
//     const uData = JSON.parse(localStorage.getItem("userIdData"));
//     const homeCity = JSON.parse(localStorage.getItem("homeData"));



//     if (uData) {
//       setAllUData(uData);
//       const mynum = uData.country_code + uData.phone_number;
//       setMobile(mynum);
//       setNationalNumber(uData.phone_number);
//       setFname(uData.fname);
//       // setLname(uData.lname);
//       setEmail(uData.email);
//       // setVerified(uData.verified);
//     }


//   }, []);

//   useEffect(() => {
//     if (phoneTouched) {
//       if (!nationalNumber) {
//         setPhoneError("Phone number is required.");
//         setOtpBtnState(true);
//       } else if (nationalNumber.length !== 10) {
//         setPhoneError("Invalid Phone number");
//         setOtpBtnState(true);
//       } else {
//         setPhoneError("");
//         setOtpBtnState(false);
//       }
//     }
//     if (allUData.phone_number === nationalNumber) {
//       setVerified(allUData.verified);
//     } else {
//       setVerified(false);
//     }
//   }, [nationalNumber, allUData, phoneTouched]);




//   const handlePhoneChange = (value, country, e, formattedValue) => {
//     const countryCode = country.dialCode;
//     const phoneNumber = value.slice(countryCode.length);

//     setMobile(value);

//     setCountryCode(countryCode);
//     setNationalNumber(phoneNumber);
//     setPhoneError("");
//     // setMobile(val);
//     // const phoneNumber = parsePhoneNumber(val);
//     // const nationalNumber = phoneNumber ? phoneNumber.nationalNumber : "";
//     // console.log("phn", phoneNumber);
//     // console.log("nationalNumber", nationalNumber);
//     // setNationalNumber(nationalNumber);
//     // const countryCode = phoneNumber ? phoneNumber.countryCallingCode : "+91";
//     // console.log("countrycode ", countryCode);
//     // setCountryCode(countryCode);
//     setPhoneTouched(true);
//   };

//   // -----------------

//   const onSendOtp = async () => {
//     if (validateFields()) {
//       try {
//         const bodySend = {
//           user_type: "Customer",
//           name: fname,
//           email: email,
//           country_code: "+" + countryCode,
//           phone_number: nationalNumber,
//           password: "123456",
//           gender: "Male",
//           dob: "1993-12-06",
//           otp: otp,
//         };
//         const userData = await userDetails(bodySend);

//         setLoginApiCall(userData.success);

//         if (userData.success === "1") {
//           try {
//             const bodySend = {
//               username: nationalNumber,
//             };
//             const userData = await sendOtp(bodySend);
//             if (userData.success === "1") {
//               setOtpState(true);
//               setOtpSendSts(true);
//             }
//           } catch (error) {
//             console.error("Error in send Otp:", error);
//           }

//           const combinedData = {
//             ...bodySend,
//             ...userData.data,
//             verified: verified,
//             fname: fname,
//           };
//           localStorage.setItem("userIdData", JSON.stringify(combinedData));
//         } else if (userData.status === "201") {
//           if (userData.message === "Phone Number Already Exist") {
//             const bodySend = {
//               username: nationalNumber,
//             };
//             const userData = await sendOtp(bodySend);
//             if (userData.success === "1") {
//               setOtpState(true);
//               setOtpSendSts(true);
//             }
//             const combinedData = {
//               ...bodySend,
//               ...userData.data,
//               verified: verified,
//               fname: fname,
//               email: email,
//               country_code: "+" + countryCode,
//             };
//             localStorage.setItem("userIdData", JSON.stringify(combinedData));

//           } else {
//             Swal.fire({
//               title: "",
//               text: userData.message,
//               icon: "error",
//             });
//           }

//         }
//       } catch (error) {
//         console.error("Error in Save user:", error);
//       }
//     }
//   };

//   useEffect(() => {
//     const verifyOtpandActive = async () => {
//       try {
//         const bodySend = {
//           otp: otp,
//           username: nationalNumber,
//         };

//         const verifyData = await activeAccount(bodySend);
      
//         setAuthToken(verifyData.data.token);
//         setAuthTokens(verifyData.data.token);
//         localStorage.setItem("authToken", verifyData.data.token);
//         if (verifyData.success === "0") {
//           setOtpError("Invalid Otp");
//         }

//         if (verifyData.status === "200") {
//           setOtpError("");
//           setVerified(true);
//           setOtpState(false);
//           const existingData = JSON.parse(localStorage.getItem("userIdData"));
//           if (existingData) {
//             existingData.verified = true;
//             localStorage.setItem("userIdData", JSON.stringify(existingData));
//           }
//         }
//       } catch (error) {
//         console.error("Error in verifying OTP:", error);
//       }
//     };


//     if (otp.length === 6) {
//       // if(loginApiCall === '0'){
//       //   LoginApi();
//       // }
//       // else{
//       //   verifyOtpandActive();
//       // }
//       verifyOtpandActive();
//     }
//   }, [otp, nationalNumber]);

//   const validateFields = () => {
//     let isValid = true;
//     // Validate Name
//     if (!fname) {
//       setFNameError(" Name is required.");
//       isValid = false;
//     } else if (fname.length < 2 || fname.length > 99) {
//       setFNameError("Name must be between 3 and 99 characters.");
//       isValid = false;
//     } else {
//       setFNameError("");
//     }

//     if (!email) {
//       setEmailError("Email is required.");
//       isValid = false;
//     } else if (!/^\S+@\S+\.\S+$/.test(email)) {
//       setEmailError("Invalid email address.");
//       isValid = false;
//     } else {
//       setEmailError("");
//     }

//     // Validate Phone
//     if (!nationalNumber) {
//       setPhoneError("Phone number is required.");
//       isValid = false;
//     } else {
//       setPhoneError("");
//     }

//     if (!pet_name) {
//       setPetNameError("Pet Name is required");
//       isValid = false;
//     } else {
//       setPetNameError("");
//     }


//     if (!pet_type) {
//       setPetTypeError("Pet Type is required");
//       isValid = false;
//     } else {
//       setPetTypeError("");
//     }

//     if (!aggression) {
//       setAggressionError("Aggression is required");
//       isValid = false;
//     } else {
//       setAggressionError("");
//     }

//     if (!breed) {
//       setBreedError("Breed is required");
//       isValid = false;
//     } else {
//       setBreedError("");
//     }
//     return isValid;
//   };



//   const handleSubmit = async (e) => {
//     e.preventDefault();
   
//     if (authTokens) {
//       try {
//         const bodySend = {
//           name: pet_name,
//           pet_type: pet_type,
//           breed: breed,
//           aggression: aggression,
//         };
//         const planData = await addPet(bodySend);
        
        
//         if (planData.success === "1") {
//           const localPetData = {
//             pet_name: pet_name,
//             pet_type: pet_type,
//             breed: breed,
//             pet_id: planData.data.id,
//             aggression: aggression,
//           };

//           localStorage.setItem("petData", JSON.stringify(localPetData));
//           localStorage.setItem("loggedin", "true");
//           window.location.href = "/appointment-details"
//         }
//       } catch (error) {

//       }

//     }
//   };

//   useEffect(() => {
//     if (authTokens === undefined) {
//       const mytoken = localStorage.getItem("authToken");
//       setAuthTokens(mytoken);
      
//     }
//   }, [authTokens]);

//   const handleNavigation = () => {
//     navigate("/login", { state: { fromRegister: "register" } });
//     setTimeout(() => {
//       window.scrollTo(0, 0);
//     }, 500);
//   };

//   const getBreeds = async () => {
//     try {
//       const breeds = await getBreedList();
//       setBreedOptions(breeds.data);
//     } catch (error) {
//       console.log("Error on Get BreedList", error);
//     }
//   };

//   const handleBreedChange = (selectedBreed) => {
//     setBreed(selectedBreed);
//     setBreedError("");
//   };

//   const handleInputChange = (e) => {
//     const userInput = e.target.value;
//     let filteredSuggestions = [];
//     if (userInput.length > 0) {
//       filteredSuggestions = breedOptions.filter(
//         (option) =>
//           option.title.toLowerCase().includes(userInput.toLowerCase())  && option.type == (pet_type ?? "Dog")
//       );
//     }

//     setSuggestions(filteredSuggestions);
//     setBreedName(userInput);
//     setShowSuggestions(true);
//     setBreedError("")
//   };

//   const handleSelectSuggestion = (breed) => {
//     setBreedName(breed.title);
//     setBreed(breed.id);
//     setShowSuggestions(false);
//   };

//   const handleInputBlur = () => {
//     setTimeout(() => {
//       setShowSuggestions(false);
//     }, 200); // Delay closing suggestions to allow selection
//   };

//   return (
//     <div className="container-xxl main-detail-container my-5">
//       <div className="row justify-content-center align-items-center px-2">
//         <div className="col-md-6 d-none d-lg-block">
//           <img src={LeftImg} alt="img" />
//         </div>
//         <div className="col-md-6 text-start formRightDiv">
//           <h5 className="text-center bold mt-3 mb-2">Add Pet Details</h5>
//           <div className="mb-5 top-typeDiv">
//             {/* <div style={{ position: 'absolute', width: '100%', top: 0,zIndex:-1, bottom: 0, left: 0, right: 0 }}>
//               <img
//                 src={PetBackground}
//                 alt="icon"
//                 className="me-2"
//                 style={{ width: '100%', height: '100%', objectFit: 'cover',  }}
//               />
//             </div> */}
//             <label
//               htmlFor="pet_type"
//               className="form-label text-center whatTypeTitle"
//             >
//               What type of pet?
//             </label>

//             <div className="selectPetDivAdd">
//               <button
//                 className={
//                   "petBtn " + (pet_type === "Dog" ? "petBtnActive" : "")
//                 }
//                 onClick={() => {
//                   setPet_type("Dog");

//                 }}
//               >
//                 <img
//                   src={pet_type === "Dog" ? dogIocn : dogDarkIocn}
//                   className="me-2"
//                   alt="Dog"
//                 />
//                 Dogs{" "}
//               </button>
//               <button
//                 className={
//                   "petBtn " + (pet_type === "Cat" ? "petBtnActive" : "")
//                 }
//                 onClick={() => {
//                   setPet_type("Cat");

//                 }}
//               >
//                 <img
//                   src={pet_type === "Cat" ? catIocn : catDarkIocn}
//                   className="me-2"
//                   alt="Cat"
//                 />
//                 Cats{" "}
//               </button>
//             </div>
//             {petTypeError && (
//               <div className="invalid-feedback">{petTypeError} </div>
//             )}
//           </div>
//           <div className="mb-3 px-3 inputWrapper">
//             <label htmlFor="pet_name" className="inputLabel">Pet's Name</label>
//             <input
//               type="text"
//               className={`inputFormControl borderradius ${petNameError ? "is-invalid" : ""}`}
//               id="pet_name"
//               name="pet_name"
//               placeholder="Please enter your pet's name..."
//               value={pet_name}
//               onChange={(e) => {
//                 setPet_name(e.target.value);
//                 setPetNameError("");
//               }}
//             />
//             {petNameError && (
//               <div className="invalid-feedback">{petNameError}</div>
//             )}
//           </div>

//           <div className="mb-3 px-3 inputWrapper">
//             <label htmlFor="pet_breed" className="inputLabel">
//               Pet's Breed
//             </label>
//             <input
//               type="text"
//               className={`inputFormControl borderradius ${breedName && suggestions.length === 0 ? "selected" : ""
//                 }`}
//               id="pet_breed"
//               name="pet_breed"
//               placeholder="Please enter your pet’s breed"
//               value={breedName}
//               onChange={handleInputChange}
//               onBlur={handleInputBlur}
//             />
//             {showSuggestions && suggestions.length > 0 && (
//               <ul className="suggestionList">
//                 {suggestions.map((breed, index) => (
//                   <li
//                     key={index}
//                     onClick={() => handleSelectSuggestion(breed)}
//                     className="suggestionItem"
//                   >
//                     {breed.title}
//                   </li>
//                 ))}
//               </ul>
//             )}
//           </div>
//           <div className="mb-3 px-3">
//             {breedError && <div className="invalid-feedback">{breedError}</div>}
//           </div>
//           <div className="mb-3 px-3">
//             <label htmlFor="petAggresive" className="form-label">
//               How aggressive is your pet?{" "}
//             </label>
//             <div className="form-row px-3 py-2">
//               <div className="form-check">
//                 <input
//                   className={`form-check-input ${
//                     aggressionError ? "is-invalid" : ""
//                   }`}
//                   type="radio"
//                   name="aggression"
//                   id="aggressive1"
//                   value="18"
//                   checked={aggression === "18"}
//                   onChange={() => {
//                     setAggression("18");
//                     setAggressionError("");
//                   }}
//                 />
//                 <label
//                   className="form-check-label radioLabel"
//                   htmlFor="aggressive1"
//                 >
//                   {" "}
//                   Low{" "}
//                 </label>
//               </div>
//               <div className="form-check">
//                 <input
//                   className={`form-check-input ${
//                     aggressionError ? "is-invalid" : ""
//                   }`}
//                   type="radio"
//                   name="aggression"
//                   id="aggressive2"
//                   value="17"
//                   checked={aggression === "17"}
//                   onChange={() => {
//                     setAggression("17");
//                     setAggressionError("");
//                   }}
//                 />
//                 <label
//                   className="form-check-label radioLabel"
//                   htmlFor="aggressive2"
//                 >
//                   {" "}
//                   Normal{" "}
//                 </label>
//               </div>
//               <div className="form-check">
//                 <input
//                   className={`form-check-input ${
//                     aggressionError ? "is-invalid" : ""
//                   }`}
//                   type="radio"
//                   name="aggression"
//                   id="aggressive3"
//                   value="16"
//                   checked={aggression === "16"}
//                   onChange={() => {
//                     setAggression("16");
//                     setAggressionError("");
//                   }}
//                 />
//                 <label
//                   className="form-check-label radioLabel"
//                   htmlFor="aggressive3"
//                 >
//                   High
//                 </label>
//               </div>
//             </div>
//             {aggressionError && (
//               <div className="invalid-feedback">{aggressionError}</div>
//             )}
//           </div>





//           <h5 className="text-center bold mt-3">
//             Add Personal Details
//           </h5>
//           <form className="my-4">
//             <div className="mb-3 formR px-3 inputWrapper">
//               <label htmlFor="name" className="inputLabel">Name</label>
//               <input
//                 type="text"
//                 className={`inputFormControl ${fnameError ? "is-invalid" : ""}`}
//                 name="name"
//                 placeholder="Full Name"
//                 onChange={(e) => {
//                   setFname(e.target.value);
//                   setFNameError("");
//                 }}
//                 value={fname}
//               />
//             </div>
//             <div className="mb-3 px-3 inputWrapper">

//               {fnameError && (
//                 <div className="invalid-feedback">{fnameError}</div>
//               )}
//             </div>


//             <div className="mb-3 px-3 inputWrapper">
//               <label htmlFor="email" className="inputLabel">Email</label>
//               <input
//                 type="email"
//                 className={`inputFormControl ${emailError ? "is-invalid" : ""}`}
//                 name="email"
//                 placeholder="Email"
//                 onChange={(e) => {
//                   setEmail((e.target.value).trim());
//                   setEmailError("");
//                 }}
//                 value={email}
//               />
//               {emailError && (
//                 <div className="invalid-feedback">{emailError}</div>
//               )}
//             </div>

//             <div className="mb-3 px-3 inputWrapper">
//               <label htmlFor="phone" className="inputLabel">Phone Number</label>
//               <div className="inputFormControl" style={{ padding: "7px 10px" }}>
//                 <PhoneInput
//                   country={"in"}
//                   onlyCountries={['in']}
//                   value={mobile}
//                   onChange={handlePhoneChange}
//                   inputMode="tel"
//                   buttonStyle={{ border: "none", backgroundColor: "transparent", }}
//                 />

//               </div>
//               {verified ? (
//                 <p className="verifiedTextPhone">Verified</p>
//               ) : otpSendSts ? (
//                 <p className="verifiedTextPhone otp-sent">Otp Sent</p>
//               ) : (
//                 <input
//                   type="button"
//                   className={"sendOtpBtnPhone " + (otpBtnState ? "dis" : "")}
//                   value="Send OTP"
//                   onClick={onSendOtp}
//                 />
//               )}

//             </div>
//             <div className="mb-3 px-3 inputWrapper">
//               {phoneError && (
//                 <div className="invalid-feedback">{phoneError}</div>
//               )}
//             </div>

//             {otpState ? (
//               <div className="mb-3 px-3 otp-wrapper">
//                 <label className="otpLabel">Enter OTP Here</label>
//                 <OtpInput
//                   value={otp}
//                   onChange={setOtp}
//                   numInputs={6}
//                   renderSeparator={<span></span>}
//                   renderInput={(props) => <input {...props} inputMode="tel" />}
//                 />
//                 {otpError && <div className="invalid-feedback">{otpError}</div>}
//               </div>
//             ) : (
//               <></>
//             )}


//             <div className="mb-3 px-3 text-center">
//               <button
//                 type="submit"
//                 className={
//                   "btn nextBtn text-center " + (verified ? "" : "disabled")
//                 }
//                 onClick={handleSubmit}
//               >
//                 Next
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default PersonalDetailsData;


import React, { useEffect, useState } from "react";
import "./style.css";
import LeftImg from "../../assets/images/Personal site-amico.svg";
// import PhoneInput from "react-phone-number-input";
// import { parsePhoneNumber } from "react-phone-number-input";
// import "react-phone-number-input/style.css";
import OtpInput from "react-otp-input";
import dogIocn from "../../assets/icons/mdi-dog.svg";
import catIocn from "../../assets/icons/mdi-cat.svg";
import dogDarkIocn from "../../assets/icons/mdi-dark-dog.svg";
import catDarkIocn from "../../assets/icons/mdi-dark-cat.svg";
import { useNavigate } from "react-router-dom";
import DogImage from "../../assets/images/d-s-dog.png";
import CatImage from "../../assets/images/d-s-cat.png";
import MapComponent from "./MapComponent";
import SinglePawIcon from '../../assets/icons/single-paw.svg';
import PetBackground from '../../assets/icons/plans-doodle.svg'
import {
  activeAccount,
  getCities,
  getStates,
  loginWithOtp,
  saveAddress,
  sendOtp,
  userDetails, addPet, getBreedList
} from "../../service/api";
import { Dropdown } from "react-bootstrap";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { setAuthToken } from "./../../service/apiService";
import ReactGoogleAutocomplete from "react-google-autocomplete";


const BreedDropdown = ({ breedOptions, selectedBreed, onBreedChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBreedName, setSelectedBreedName] = useState("");
  const [dropdownWidth, setDropdownWidth] = useState(null);

  const filteredBreeds = breedOptions.filter((breed) =>
    breed.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleBreedSelect = (breedId, breedTitle) => {

    setSelectedBreedName(breedTitle);
    onBreedChange(breedId);
  };

  return (
    <div className="breed-drop px-3">

      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          {selectedBreedName ? selectedBreedName : "Select Breed"}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "scroll" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {filteredBreeds.map((breed) => (
            <Dropdown.Item
              key={breed.id}
              onClick={() => handleBreedSelect(breed.id, breed.title)}
            >
            {breed.title}
            </Dropdown.Item>
          ))}
          
        </Dropdown.Menu>
        <div className="dropdown-menu-prepend">
          <div className="dropdown-toggle-append" />
        </div>
      </Dropdown>
    </div>
  );
};

const PersonalDetailsData = () => {
  const navigate = useNavigate();

  // -----------------------------------------
  const [utmParams, setUtmParams] = useState({});

  useEffect(() => {
    // Retrieve UTM parameters from localStorage
    const savedParams = JSON.parse(localStorage.getItem('utmParams'));
    if (savedParams) {
      setUtmParams(savedParams);
    }
  }, []);

  // -----------------------------------------

  const [otpState, setOtpState] = useState(false);
  const [otp, setOtp] = useState("");


  const [fname, setFname] = useState("");

  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [mobile, setMobile] = useState("");
  const [verified, setVerified] = useState(false);
  const [nationalNumber, setNationalNumber] = useState();

  const [allUData, setAllUData] = useState([]);

  const [fnameError, setFNameError] = useState("");

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [otpError, setOtpError] = useState("");
  const [phoneTouched, setPhoneTouched] = useState(false);

  const [otpBtnState, setOtpBtnState] = useState(false);
  const [loginApiCall, setLoginApiCall] = useState();
  const [otpSendSts, setOtpSendSts] = useState(false);

  const [authTokens, setAuthTokens] = useState();
  const [googleAutocomplete, setGoogleAutocomplete] = useState();

  const [pet_name, setPet_name] = useState("");
  const [pet_type, setPet_type] = useState("");
  const [breed, setBreed] = useState("");
  const [breedName, setBreedName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [breedOptions, setBreedOptions] = useState([]);
  const [dob, setDob] = useState("");
  const [petNameError, setPetNameError] = useState("");
  const [petTypeError, setPetTypeError] = useState("");
  const [breedError, setBreedError] = useState("");
  const [aggressionError, setAggressionError] = useState("");
  const [aggression, setAggression] = useState("");
  

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  },[])

  useEffect(() => {
    const homeData = JSON.parse(localStorage.getItem("homeData"));
    if (homeData) {
      setPet_type(homeData.selectedPet);
    }

    const petData = JSON.parse(localStorage.getItem("petData"));
    if (petData) {
      setPet_name(petData.pet_name);
      setBreed(petData.breed);

    }

    getBreeds();
  }, []);

  useEffect(() => {
    const uData = JSON.parse(localStorage.getItem("userIdData"));
    const homeCity = JSON.parse(localStorage.getItem("homeData"));



    if (uData) {
      setAllUData(uData);
      const mynum = uData.country_code + uData.phone_number;
      setMobile(mynum);
      setNationalNumber(uData.phone_number);
      setFname(uData.fname);
      // setLname(uData.lname);
      setEmail(uData.email);
      // setVerified(uData.verified);
    }


  }, []);

  useEffect(() => {
    if (phoneTouched) {
      if (!nationalNumber) {
        setPhoneError("Phone number is required.");
        setOtpBtnState(true);
      } else if (nationalNumber.length !== 10) {
        setPhoneError("Invalid Phone number");
        setOtpBtnState(true);
      } else {
        setPhoneError("");
        setOtpBtnState(false);
      }
    }
    if (allUData.phone_number === nationalNumber) {
      setVerified(allUData.verified);
    } else {
      setVerified(false);
    }
  }, [nationalNumber, allUData, phoneTouched]);




  const handlePhoneChange = (value, country, e, formattedValue) => {
    const countryCode = country.dialCode;
    const phoneNumber = value.slice(countryCode.length);

    setMobile(value);

    setCountryCode(countryCode);
    setNationalNumber(phoneNumber);
    setPhoneError("");
    // setMobile(val);
    // const phoneNumber = parsePhoneNumber(val);
    // const nationalNumber = phoneNumber ? phoneNumber.nationalNumber : "";
    // console.log("phn", phoneNumber);
    // console.log("nationalNumber", nationalNumber);
    // setNationalNumber(nationalNumber);
    // const countryCode = phoneNumber ? phoneNumber.countryCallingCode : "+91";
    // console.log("countrycode ", countryCode);
    // setCountryCode(countryCode);
    setPhoneTouched(true);
  };

  // -----------------

  // this was the otp function before utm data fetching
  // const onSendOtp = async () => {
  //   if (validateFields()) {
  //     try {
  //       const bodySend = {
  //         user_type: "Customer",
  //         name: fname,
  //         email: email,
  //         country_code: "+" + countryCode,
  //         phone_number: nationalNumber,
  //         password: "123456",
  //         gender: "Male",
  //         dob: "1993-12-06",
  //         otp: otp,

  //         // ------------utm work
  //       };
  //       const userData = await userDetails(bodySend);

  //       setLoginApiCall(userData.success);

  //       if (userData.success === "1") {
  //         try {
  //           const bodySend = {
  //             username: nationalNumber,
  //           };
  //           const userData = await sendOtp(bodySend);
  //           if (userData.success === "1") {
  //             setOtpState(true);
  //             setOtpSendSts(true);
  //           }
  //         } catch (error) {
  //           console.error("Error in send Otp:", error);
  //         }

  //         const combinedData = {
  //           ...bodySend,
  //           ...userData.data,
  //           verified: verified,
  //           fname: fname,
  //         };
  //         localStorage.setItem("userIdData", JSON.stringify(combinedData));
  //       } else if (userData.status === "201") {
  //         if (userData.message === "Phone Number Already Exist") {
  //           const bodySend = {
  //             username: nationalNumber,
  //           };
  //           const userData = await sendOtp(bodySend);
  //           if (userData.success === "1") {
  //             setOtpState(true);
  //             setOtpSendSts(true);
  //           }
  //           const combinedData = {
  //             ...bodySend,
  //             ...userData.data,
  //             verified: verified,
  //             fname: fname,
  //             email: email,
  //             country_code: "+" + countryCode,
  //           };
  //           localStorage.setItem("userIdData", JSON.stringify(combinedData));

  //         } else {
  //           Swal.fire({
  //             title: "",
  //             text: userData.message,
  //             icon: "error",
  //           });
  //         }

  //       }
  //     } catch (error) {
  //       console.error("Error in Save user:", error);
  //     }
  //   }
  // };
  // end's here

  // this is the new function of otp which the fetching the data from the utp..

  const onSendOtp = async () => {
    if (validateFields()) {
      try {
        const utm_id = localStorage.getItem('utm_id');
        const utmSource = localStorage.getItem('utm_source');
        const utmMedium = localStorage.getItem('utm_medium');
        const utmCampaign = localStorage.getItem('utm_campaign');
        const utmContent = localStorage.getItem('utm_content');
        const utmTerm = localStorage.getItem('utm_term');
  
        const bodySend = {
          user_type: "Customer",
          name: fname,
          email: email,
          country_code: "+" + countryCode,
          phone_number: nationalNumber,
          password: "123456",
          gender: "Male",
          dob: "1993-12-06",
          otp: otp,
          
          // UTM parameters
          utm_id: utm_id,
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
          utm_content: utmContent,
          utm_term: utmTerm
        };
  
        const userData = await userDetails(bodySend);
        setLoginApiCall(userData.success);
  
        if (userData.success === "1") {
          try {
            const bodySendOtp = {
              username: nationalNumber,
            };
            const otpData = await sendOtp(bodySendOtp);
            if (otpData.success === "1") {
              setOtpState(true);
              setOtpSendSts(true);
            }
          } catch (error) {
            console.error("Error in send Otp:", error);
          }
  
          const combinedData = {
            ...bodySend,
            ...userData.data,
            verified: verified,
            fname: fname,
          };
          localStorage.setItem("userIdData", JSON.stringify(combinedData));
        } else if (userData.status === "201") {
          if (userData.message === "Phone Number Already Exist") {
            const bodySendOtp = {
              username: nationalNumber,
            };
            const otpData = await sendOtp(bodySendOtp);
            if (otpData.success === "1") {
              setOtpState(true);
              setOtpSendSts(true);
            }
            const combinedData = {
              ...bodySend,
              ...otpData.data,
              verified: verified,
              fname: fname,
              email: email,
              country_code: "+" + countryCode,
            };
            localStorage.setItem("userIdData", JSON.stringify(combinedData));
          } else {
            Swal.fire({
              title: "",
              text: userData.message,
              icon: "error",
            });
          }
        }
      } catch (error) {
        console.error("Error in Save user:", error);
      }
    }
  };

  // -------------------------------------------------------- end here's
  
  useEffect(() => {
    const verifyOtpandActive = async () => {
      try {
        const bodySend = {
          otp: otp,
          username: nationalNumber,
        };

        const verifyData = await activeAccount(bodySend);
      
        setAuthToken(verifyData.data.token);
        setAuthTokens(verifyData.data.token);
        localStorage.setItem("authToken", verifyData.data.token);
        if (verifyData.success === "0") {
          setOtpError("Invalid Otp");
        }

        if (verifyData.status === "200") {
          setOtpError("");
          setVerified(true);
          setOtpState(false);
          const existingData = JSON.parse(localStorage.getItem("userIdData"));
          if (existingData) {
            existingData.verified = true;
            localStorage.setItem("userIdData", JSON.stringify(existingData));
          }
        }
      } catch (error) {
        console.error("Error in verifying OTP:", error);
      }
    };


    if (otp.length === 6) {
      // if(loginApiCall === '0'){
      //   LoginApi();
      // }
      // else{
      //   verifyOtpandActive();
      // }
      verifyOtpandActive();
    }
  }, [otp, nationalNumber]);

  const validateFields = () => {
    let isValid = true;
    // Validate Name
    if (!fname) {
      setFNameError(" Name is required.");
      isValid = false;
    } else if (fname.length < 2 || fname.length > 99) {
      setFNameError("Name must be between 3 and 99 characters.");
      isValid = false;
    } else {
      setFNameError("");
    }

    if (!email) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError("Invalid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Validate Phone
    if (!nationalNumber) {
      setPhoneError("Phone number is required.");
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (!pet_name) {
      setPetNameError("Pet Name is required");
      isValid = false;
    } else {
      setPetNameError("");
    }


    if (!pet_type) {
      setPetTypeError("Pet Type is required");
      isValid = false;
    } else {
      setPetTypeError("");
    }

    if (!aggression) {
      setAggressionError("Aggression is required");
      isValid = false;
    } else {
      setAggressionError("");
    }

    if (!breed) {
      setBreedError("Breed is required");
      isValid = false;
    } else {
      setBreedError("");
    }
    return isValid;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
   
    if (authTokens) {
      try {
        const bodySend = {
          name: pet_name,
          pet_type: pet_type,
          breed: breed,
          aggression: aggression,
        };
        const planData = await addPet(bodySend);
        
        
        if (planData.success === "1") {
          const localPetData = {
            pet_name: pet_name,
            pet_type: pet_type,
            breed: breed,
            pet_id: planData.data.id,
            aggression: aggression,
          };

          localStorage.setItem("petData", JSON.stringify(localPetData));
          localStorage.setItem("loggedin", "true");
          window.location.href = "/appointment-details"
        }
      } catch (error) {

      }

    }
  };

  useEffect(() => {
    if (authTokens === undefined) {
      const mytoken = localStorage.getItem("authToken");
      setAuthTokens(mytoken);
      
    }
  }, [authTokens]);

  const handleNavigation = () => {
    navigate("/login", { state: { fromRegister: "register" } });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const getBreeds = async () => {
    try {
      const breeds = await getBreedList();
      setBreedOptions(breeds.data);
    } catch (error) {
      console.log("Error on Get BreedList", error);
    }
  };

  const handleBreedChange = (selectedBreed) => {
    setBreed(selectedBreed);
    setBreedError("");
  };

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    let filteredSuggestions = [];
    if (userInput.length > 0) {
      filteredSuggestions = breedOptions.filter(
        (option) =>
          option.title.toLowerCase().includes(userInput.toLowerCase())  && option.type == (pet_type ?? "Dog")
      );
    }

    setSuggestions(filteredSuggestions);
    setBreedName(userInput);
    setShowSuggestions(true);
    setBreedError("")
  };

  const handleSelectSuggestion = (breed) => {
    setBreedName(breed.title);
    setBreed(breed.id);
    setShowSuggestions(false);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200); // Delay closing suggestions to allow selection
  };


  
  return (
    <div className="container-xxl main-detail-container my-5">
      <div className="row justify-content-center align-items-center px-2">
        <div className="col-md-6 d-none d-lg-block">
          <img src={LeftImg} alt="img" />
        </div>
        <div className="col-md-6 text-start formRightDiv">
          <h5 className="text-center bold mt-3 mb-2">Add Pet Details</h5>
          <div className="mb-5 top-typeDiv">
            {/* <div style={{ position: 'absolute', width: '100%', top: 0,zIndex:-1, bottom: 0, left: 0, right: 0 }}>
              <img
                src={PetBackground}
                alt="icon"
                className="me-2"
                style={{ width: '100%', height: '100%', objectFit: 'cover',  }}
              />
            </div> */}
            <label
              htmlFor="pet_type"
              className="form-label text-center whatTypeTitle"
            >
              What type of pet?
            </label>

            <div className="selectPetDivAdd">
              <button
                className={
                  "petBtn " + (pet_type === "Dog" ? "petBtnActive" : "")
                }
                onClick={() => {
                  setPet_type("Dog");

                }}
              >
                <img
                  src={pet_type === "Dog" ? dogIocn : dogDarkIocn}
                  className="me-2"
                  alt="Dog"
                />
                Dogs{" "}
              </button>
              <button
                className={
                  "petBtn " + (pet_type === "Cat" ? "petBtnActive" : "")
                }
                onClick={() => {
                  setPet_type("Cat");

                }}
              >
                <img
                  src={pet_type === "Cat" ? catIocn : catDarkIocn}
                  className="me-2"
                  alt="Cat"
                />
                Cats{" "}
              </button>
            </div>
            {petTypeError && (
              <div className="invalid-feedback">{petTypeError} </div>
            )}
          </div>
          <div className="mb-3 px-3 inputWrapper">
            <label htmlFor="pet_name" className="inputLabel">Pet's Name</label>
            <input
              type="text"
              className={`inputFormControl borderradius ${petNameError ? "is-invalid" : ""}`}
              id="pet_name"
              name="pet_name"
              placeholder="Please enter your pet's name..."
              value={pet_name}
              onChange={(e) => {
                setPet_name(e.target.value);
                setPetNameError("");
              }}
            />
            {petNameError && (
              <div className="invalid-feedback">{petNameError}</div>
            )}
          </div>

          <div className="mb-3 px-3 inputWrapper">
            <label htmlFor="pet_breed" className="inputLabel">
              Pet's Breed
            </label>
            <input
              type="text"
              className={`inputFormControl borderradius ${breedName && suggestions.length === 0 ? "selected" : ""
                }`}
              id="pet_breed"
              name="pet_breed"
              placeholder="Please enter your pet’s breed"
              value={breedName}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
            />
            {showSuggestions && suggestions.length > 0 && (
              <ul className="suggestionList">
                {suggestions.map((breed, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectSuggestion(breed)}
                    className="suggestionItem"
                  >
                    {breed.title}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="mb-3 px-3">
            {breedError && <div className="invalid-feedback">{breedError}</div>}
          </div>
          <div className="mb-3 px-3">
            <label htmlFor="petAggresive" className="form-label">
              How aggressive is your pet?{" "}
            </label>
            <div className="form-row px-3 py-2">
              <div className="form-check">
                <input
                  className={`form-check-input ${
                    aggressionError ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="aggression"
                  id="aggressive1"
                  value="18"
                  checked={aggression === "18"}
                  onChange={() => {
                    setAggression("18");
                    setAggressionError("");
                  }}
                />
                <label
                  className="form-check-label radioLabel"
                  htmlFor="aggressive1"
                >
                  {" "}
                  Low{" "}
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${
                    aggressionError ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="aggression"
                  id="aggressive2"
                  value="17"
                  checked={aggression === "17"}
                  onChange={() => {
                    setAggression("17");
                    setAggressionError("");
                  }}
                />
                <label
                  className="form-check-label radioLabel"
                  htmlFor="aggressive2"
                >
                  {" "}
                  Normal{" "}
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${
                    aggressionError ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="aggression"
                  id="aggressive3"
                  value="16"
                  checked={aggression === "16"}
                  onChange={() => {
                    setAggression("16");
                    setAggressionError("");
                  }}
                />
                <label
                  className="form-check-label radioLabel"
                  htmlFor="aggressive3"
                >
                  High
                </label>
              </div>
            </div>
            {aggressionError && (
              <div className="invalid-feedback">{aggressionError}</div>
            )}
          </div>

          <h5 className="text-center bold mt-3">
            Add Personal Details
          </h5>
          <form className="my-4">
            <div className="mb-3 formR px-3 inputWrapper">
              <label htmlFor="name" className="inputLabel">Name</label>
              <input
                type="text"
                className={`inputFormControl ${fnameError ? "is-invalid" : ""}`}
                name="name"
                placeholder="Full Name"
                onChange={(e) => {
                  setFname(e.target.value);
                  setFNameError("");
                }}
                value={fname}
              />
            </div>
            <div className="mb-3 px-3 inputWrapper">

              {fnameError && (
                <div className="invalid-feedback">{fnameError}</div>
              )}
            </div>


            <div className="mb-3 px-3 inputWrapper">
              <label htmlFor="email" className="inputLabel">Email</label>
              <input
                type="email"
                className={`inputFormControl ${emailError ? "is-invalid" : ""}`}
                name="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail((e.target.value).trim());
                  setEmailError("");
                }}
                value={email}
              />
              {emailError && (
                <div className="invalid-feedback">{emailError}</div>
              )}
            </div>

            <div className="mb-3 px-3 inputWrapper">
              <label htmlFor="phone" className="inputLabel">Phone Number</label>
              <div className="inputFormControl" style={{ padding: "7px 10px" }}>
                <PhoneInput
                  country={"in"}
                  onlyCountries={['in']}
                  value={mobile}
                  onChange={handlePhoneChange}
                  inputMode="tel"
                  buttonStyle={{ border: "none", backgroundColor: "transparent", }}
                />

              </div>
              {verified ? (
                <p className="verifiedTextPhone">Verified</p>
              ) : otpSendSts ? (
                <p className="verifiedTextPhone otp-sent">Otp Sent</p>
              ) : (
                <input
                  type="button"
                  className={"sendOtpBtnPhone " + (otpBtnState ? "dis" : "")}
                  value="Send OTP"
                  onClick={onSendOtp}
                />
              )}

            </div>
            <div className="mb-3 px-3 inputWrapper">
              {phoneError && (
                <div className="invalid-feedback">{phoneError}</div>
              )}
            </div>

            {otpState ? (
              <div className="mb-3 px-3 otp-wrapper">
                <label className="otpLabel">Enter OTP Here</label>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} inputMode="tel" />}
                />
                {otpError && <div className="invalid-feedback">{otpError}</div>}
              </div>
            ) : (
              <></>
            )}


            <div className="mb-3 px-3 text-center">
              <button
                type="submit"
                className={
                  "btn nextBtn text-center " + (verified ? "" : "disabled")
                }
                onClick={handleSubmit}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* ------------------ */}
    </div>
  );
};

export default PersonalDetailsData;

