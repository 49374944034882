
import React, { useEffect, useState } from 'react'
import { addToCart, getAddress, getProductPlans } from '../../service/api';
import StarIcon from "../../assets/icons/star.svg";
import RightCheck from "../../assets/icons/rightCheck.svg";
import DropIcon from "../../assets/icons/DropDownIcon.svg";
import RightBox from "../../assets/icons/right-box.svg";

import "./style.css";
import { packagesData } from '../../component/Constants/PackageData';
import { useNavigate } from 'react-router-dom';

function PackageConfirmation() {
  const navigate = useNavigate();
  const [bookingPlans, setBookingPlans] = useState([]);
  const [city, setCity] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [uAddress, setUAddress] = useState();  
    useEffect(() => {
      getAllPlans();
  
    }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  },[])

  



  const getAllPlans = async () => {
    const localCity = JSON.parse(localStorage.getItem("homeData"));
    const storedPlan = JSON.parse(localStorage.getItem('selectedPlan'));
    const existingPetData = JSON.parse(localStorage.getItem("petData"));
    const existingData = JSON.parse(localStorage.getItem("userIdData"));
    
    setCity(existingData.city ?? localCity.selectedCity.title ?? '')
    try {
      const bodySend = {
        category_id: "1",
        city_id: existingData.selectedCityId ?? localCity.selectedCityId,
        isMultipleCity: true,
        pet: existingPetData.pet_type,
      };

      
      const planData = await getProductPlans(bodySend);
      setBookingPlans(planData.data);
      const isStoredPlanValid = planData?.data.some(plan => plan.id === storedPlan?.id);

    if (isStoredPlanValid) {
      setSelectedPlan(storedPlan);
    } else {
      setSelectedPlan(planData.data[0]);
      localStorage.setItem("selectedPlan", JSON.stringify(planData.data[0]))
    }
      //   setPlanLoadingHeader(false);
    } catch (error) {
      console.log("Error on get palns", error);
    }
  };

  const handlePlanSelect = (plan) => {
    localStorage.setItem('selectedPlan', JSON.stringify(plan));
    setSelectedPlan(plan);
  };

  const submitSlot = async () => {

    const bookingPlanData = JSON.parse(localStorage.getItem("selectedPlan"));
    const homeData = JSON.parse(localStorage.getItem("homeData"));
    const existingData = JSON.parse(localStorage.getItem("petData"));
    const userData = JSON.parse(localStorage.getItem("userIdData"));
    

    if(existingData){

      const bodySend = {
        item_id: bookingPlanData?.id,
        // variation_id: selectedTimeSlote.id,
        clear_cart: 0,
        quantity: 1,
        city_id: userData.selectedCityId ?? homeData.selectedCityId,
        isMultipleCity: false,
        appoinment_date: existingData?.selectedDate,
        appoinment_time: existingData?.selectedSlot?.slug,
        pet_id: existingData.pet_id,
      };
      try {
        const cart = await addToCart(bodySend);
        if(cart.status == 200 || cart.message == "Item already in cart"){

          navigate("/myorder");
          // setTimeout(() => {
          //   window.scrollTo(0, 0);
          // }, 500);
        }

      } catch (error) {
        console.log("Error on Add to Cart", error);
      }
    }
  }

  return (

    // Here's can be the problem
    <div className='container-xxl mt-20 mb-5 text-center package-confirm-container'>
      <h1 className="packageConfirmTitle">
  Packages {city != ""  ? `for` : ''} <span style={{ color: '#F29A4C'  }}>{city}</span>
</h1>

      <div className='packages-list'>
        {bookingPlans.map((plan, index) => {
          const planName = plan.title.replace(/Dog|Cat|Grooming/g, '').trim();
          const includedServices = packagesData[planName]?.included || [];
          const isSelected = selectedPlan?.id === plan.id;

          return (
            <div
              key={index}
              className={`booking-card-row col-6 ${isSelected ? 'selected' : ''}`}
              onClick={() => handlePlanSelect(plan)}
            >
              <div className={`bookingplanHeader ${isSelected ? '' : 'collapsed'}`} data-bs-toggle="collapse"
                data-bs-target={!isSelected ? `#collapseExample${index}` : undefined}
                aria-expanded={isSelected ? "true" : "false"}
                aria-controls={`collapseExample${index}`}>
                <div className="bookingplanMainDiv">
                  <div className="bookingplanOffDiv">
                    <div className='bookingplanHeadingContainer'>
                        <div className='bookingSelectedBox'>
                         {isSelected ?  <img src={RightBox} className='bookingSelectedBoxCheck' /> : <div></div>}
                        </div>
                      <span className="bookingplanHeading">
                        {planName}
                      </span>
                    </div>
                    <div className='confirmPriceContainer'>
                      <div className="bookingRightPrice">
                        <div className='bookplanPriceContainer'>
                          <span className="bookplanPriceMRP">₹{plan.price}/-</span>
                          <span className="bookplanPrice">₹{plan.offer_price}/-</span>
                        </div>
                      </div>
                      <div className="dropCardIcon">
                        <img src={DropIcon} alt="icon" className="me-2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`collapse  ${isSelected ? 'show' : ''}`} style={{visibility:'visible'}}  id={`collapseExample${index}` }>
                  <div className="card card-body border-0 p-0 mt-3 brb">
                    <ul className="bookingplanDescList">
                       {includedServices.map((item, subIndex) => (
                        <li key={subIndex} className="leastItemCard text-lg">
                          <img
                            src="https://dm6g3jbka53hp.cloudfront.net/static-images/grooming-home__packages__green-tick-icon.svg"
                            alt="icon"
                            className="me-2"
                            style={{ width: "25px", height: "25px" }}
                          />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {/* <div className='packages-list'>
        {bookingPlans.map((plan, index) => {

          const handlePlanSelect = () => {
            localStorage.setItem('selectedPlan', JSON.stringify(plan));
          };
          const planName = plan.title.replace(/Dog|Cat|Grooming/g, '').trim();
          const planDescriptions = plan.description.split(/\r\n|\n/);

          // const matchedItems = petGroomingServices.filter(item => planDescriptions.includes(item));
          // const unmatchedItems = petGroomingServices.filter(item => !planDescriptions.includes(item));
          const includedServices = packagesData[planName]?.included || [];
          const notIncludedServices = packagesData[planName]?.not_included || [];
          return (
            <div key={index} className="booking-card-row col-6">

              <input
                type="radio"
                name="bookingPlan"
                id={`planRadio${index}`}
                className="custom-radio"
                onChange={handlePlanSelect}
              />
              <label htmlFor={`planRadio${index}`} className="custom-radio-label me-3">
                <span className="custom-checkmark"></span>
              </label>
              <div className={`bookingplanHeader ${index === 0 ? "" : "collapsed"}`} data-bs-toggle="collapse"
                data-bs-target={`#collapseExample${index}`}
                aria-expanded={index === 0 ? "true" : "false"}
                aria-controls={`collapseExample${index}`}>
                <div className="bookingplanMainDiv">

                  <div className="bookingplanOffDiv">
                    <div>
                      <span className="bookingplanHeading">
                        {plan.title.replace(/Dog|Cat|Grooming/g, '').trim()}{" "}
                      </span>
                    </div>
                    <div className='confirmPriceContainer'>
                      <div className="bookingRightPrice">
                        <div className='bookplanPriceContainer'>
                          <span className="bookplanPriceMRP" >₹{plan.price}/-</span>
                          <span className="bookplanPrice">₹{plan.offer_price}/-</span>
                        </div>
                      </div>
                      <div className="dropCardIcon">
                        <img src={DropIcon} alt="icon" className="me-2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`collapse ${index === 0 ? "show" : ""}`} id={`collapseExample${index}`}>
                  <div className="card card-body border-0 p-0 mt-3 brb">
                    <ul className="bookingplanDescList">
                      {includedServices.map((item, subIndex) => (
                        <li key={subIndex}>
                          <img src={RightCheck} alt="icon" className="me-2" style={{ width: "20px" }} /> {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div> */}
      <div className="text-center my-4">
        <button
          type="submit"
          className={"btn nextBtn text-center mt-4"}
          onClick={submitSlot}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default PackageConfirmation



// is wale code se bss wo default delhi hi dekhra hai chahe kch bhi set krlo maine upar purane wale code se code copy krke paste kra hai and it is working and i future kbhi need padta hai to isme change krke use kr skte hai, but for now it is commented here 

// import React, { useEffect, useState } from 'react'
// import { addToCart, getAddress, getProductPlans } from '../../service/api';
// import StarIcon from "../../assets/icons/star.svg";
// import RightCheck from "../../assets/icons/rightCheck.svg";
// import DropIcon from "../../assets/icons/DropDownIcon.svg";
// import RightBox from "../../assets/icons/right-box.svg";

// import "./style.css";
// import { packagesData } from '../../component/Constants/PackageData';
// import { useNavigate } from 'react-router-dom';

// function PackageConfirmation() {
//   const navigate = useNavigate();
//   const [bookingPlans, setBookingPlans] = useState([]);
//   const [city, setCity] = useState("");
//   const [selectedPlan, setSelectedPlan] = useState(null);
//   const [uAddress, setUAddress] = useState();


  
//     useEffect(() => {
//       getAllPlans();
  
//     }, []);

//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'instant'
//     });
//   },[])

  



//   const getAllPlans = async () => {
//     const localCity = JSON.parse(localStorage.getItem("homeData"));
//     const storedPlan = JSON.parse(localStorage.getItem('selectedPlan'));
//     const existingPetData = JSON.parse(localStorage.getItem("petData"));
//     const existingData = JSON.parse(localStorage.getItem("userIdData"));
    
//     setCity(existingData.city ?? localCity.selectedCity.title ?? '')
//     try {
//       const bodySend = {
//         category_id: "1",
//         city_id: existingData.selectedCityId ?? localCity.selectedCityId,
//         isMultipleCity: true,
//         pet: existingPetData.pet_type,
//       };

      
//       const planData = await getProductPlans(bodySend);
//       setBookingPlans(planData.data);
//       const isStoredPlanValid = planData?.data.some(plan => plan.id === storedPlan?.id);

//     if (isStoredPlanValid) {
//       setSelectedPlan(storedPlan);
//     } else {
//       setSelectedPlan(planData.data[0]);
//       localStorage.setItem("selectedPlan", JSON.stringify(planData.data[0]))
//     }
//       //   setPlanLoadingHeader(false);
//     } catch (error) {
//       console.log("Error on get palns", error);
//     }
//   };

//   const handlePlanSelect = (plan) => {
//     localStorage.setItem('selectedPlan', JSON.stringify(plan));
//     setSelectedPlan(plan);
//   };

//   const submitSlot = async () => {

//     const bookingPlanData = JSON.parse(localStorage.getItem("selectedPlan"));
//     const homeData = JSON.parse(localStorage.getItem("homeData"));
//     const existingData = JSON.parse(localStorage.getItem("petData"));
//     const userData = JSON.parse(localStorage.getItem("userIdData"));
    

//     if(existingData){

//       const bodySend = {
//         item_id: bookingPlanData?.id,
//         // variation_id: selectedTimeSlote.id,
//         clear_cart: 0,
//         quantity: 1,
//         city_id: userData.selectedCityId ?? homeData.selectedCityId,
//         isMultipleCity: false,
//         appoinment_date: existingData?.selectedDate,
//         appoinment_time: existingData?.selectedSlot?.slug,
//         pet_id: existingData.pet_id,
//       };
//       try {
//         const cart = await addToCart(bodySend);
//         if(cart.status == 200 || cart.message == "Item already in cart"){

//           navigate("/myorder");
//           // setTimeout(() => {
//           //   window.scrollTo(0, 0);
//           // }, 500);
//         }

//       } catch (error) {
//         console.log("Error on Add to Cart", error);
//       }
//     }
//   }