import React from "react";
import "./BecomeAPartner.css"; // Make sure to create this CSS file

export default function BecomeAPartner() {
  return (
    <div className="page-container">
      <main className="content ">
        <h2 className="heading">coming  soon</h2>
      </main>
    </div>
  );
}
