import React, { useState } from "react";
import "./style.css"; // Ensure this CSS file reflects the new styles
import {
  FaBone,
  FaDog,
  FaHome,
  FaShieldAlt,
  FaPhoneAlt,
  FaCheckCircle,
  FaStar,
  FaHeadset,
} from "react-icons/fa";

import aboutus from "../../assets/img/aboutus.svg";
import WhyChoose from "../Home/WhyChooes";
// import whychooseAbout from '../Home/whyChooseAbout';

// import {
//   FaPaw,
//   FaHotel,
//   FaStethoscope,
//   FaWalking,
//   FaPlane,
//   FaShopify,
//   FaHeart,
//   FaBlog,
//   FaExclamationTriangle,
// } from "react-icons/fa";

import img1 from "../../assets/reviewsImages/img.jpeg";
import img2 from "../../assets/reviewsImages/img2.jpg";
import img3 from "../../assets/reviewsImages/img3.jpg";
import img4 from "../../assets/reviewsImages/img4.jpg";
import img5 from "../../assets/reviewsImages/img5.jpg";
import img6 from "../../assets/reviewsImages/img6.jpg";

const reviews = [
  {
    name: "Megha Jetley",
    role: "Local Guide·19 reviews·23 photos",
    review:
      "I recently took my beloved pet to Dear Pet Expert Grooming Service, and I cannot express how satisfied I am with their exceptional service! From start to finish, they provided top-notch care and grooming for my fur baby.",
    image: img1,
    stars: 5,
  },
  {
    name: "Ashish Dewan",
    role: "Local Guide·10 reviews·66 photos",
    review:
      "My poodle has been taking grooming services from Dear Pet for the past one year. The groomers really handle him with utmost care, love and patience. Very happy with the services and wish to continue.",
    image: img2,
    stars: 4,
  },
  {
    name: "Shivansh Bameta /Priyanka Bameta",
    role: "Local Guide·35 reviews·1 photo",
    review:
      "Ollie absolutely enjoyed the service, she was handled very nicely and professionally. The guys were clear about their work and, calmed her before drying and cleaning ears and all.",
    image: img3,
    stars: 5,
  },
  {
    name: "Ankit Goel",
    role: "Local Guide·153 reviews·12 photos",
    review:
      "I have used other pet grooming services as well, but Dearpet proved better for me. I would highly recommend them. Groomers are well-trained and did everything even in my absence to perfection.",
    image: img4,
    stars: 5,
  },
  {
    name: "Aditi Bajpai",
    role: "5 reviews·1 photo",
    review:
      "Wonderful service. The professionals were all very helpful and kind! They were super friendly and my baby loved them. They were very gentle and provided the services as requested and on time!",
    image: img5,
    stars: 4,
  },
  {
    name: "Pooja Pant",
    role: "4 reviews",
    review:
      "Awesome experience with them. They took care of my baby really well, bathed him really good. Everything was hygienic and perfect. They even cleaned the area after the session. Great service 👍🏽",
    image: img6,
    stars: 5,
  },
];

export default function AboutUs() {
  const [currentPage, setCurrentPage] = useState(0);
  const reviewsPerPage = 2;
  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  const handlePrev = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1));
  };

  const currentReviews = reviews.slice(
    currentPage * reviewsPerPage,
    (currentPage + 1) * reviewsPerPage
  );

  return (
    <>
      <div className="container mt-5">
        <div className="hero-section">
          {/* <img
            src="https://dearpetindia.com/cdn/shop/t/69/assets/nitinstorywebv.svg?v=74358716086746599721716360361"
            alt=""
          /> */}
          <img src={aboutus} />
        </div>
        <p>
          Founded in 2019, Dearpet is India’s largest network of 5-star pet care
          service providers
        </p>

        <div className="content-section">
          <h2 className="font-extrabold textcolor">About Us</h2>
          <p>
            DearPet, we believe that every pet deserves the best care, love, and
            nourishment. Our story began with the unbreakable bond between Nitin
            and his beloved dog, Casper. Witnessing Casper's struggle with
            health issues, Nitin was inspired to create something that could
            make a real difference in the lives of pets. Driven by a passion to
            heal and nourish, DearPet was born.
          </p>
          <p>
            Our journey started with a simple yet powerful mission: to craft
            meals that guarantee pets thrive. We blend deep affection with
            nutritional science in every DearPet recipe, ensuring that your
            furry friend gets the best of both worlds. Our fresh dog food is
            made with high-quality ingredients, carefully selected to meet the
            unique needs of each pet, providing them with the nutrition they
            need to lead a healthy and happy life.
          </p>
          <p>
            But we didn’t stop at food. At DearPet, we offer a comprehensive
            range of products and services designed to make pet care easier and
            more fulfilling. From at-home grooming kits that make grooming a
            breeze to expert vet consultations that provide personalized care,
            we’re here to support every aspect of your pet’s well-being.
          </p>
          <p>
            DearPet is more than just a brand; it’s a promise to every pet
            parent that we will always put your pet’s health and happiness
            first. Join us on this journey, and let’s create a world where
            every pet thrives.
          </p>

          <h2 className="pb-4 pt-4 font-bold textcolor">
            Celebrating Our Success
          </h2>
          <div className="stats font-bold">
            <div className="stat">
              <h3>35000+</h3>
              <p>Pets groomed</p>
            </div>
            <div className="stat">
              <h3>1lakh+</h3>
              <p>Products Delivered</p>
            </div>
            <div className="stat">
              <h3>450+</h3>
              <p>5 star reviews</p>
            </div>
            <div className="stat">
              <h3>100+</h3>
              <p>Professional groomers</p>
            </div>
            <div className="stat">
              <h3>1.5lakh+</h3>
              <p>Happy customers served</p>
            </div>
          </div>

          {/* <p>
            Good Pets. Good people. Whether you need a pet groomer for the spa
            session or dog training for a well-behaved pet, Dearpet has got you,
            your pets, and your pet's lifestyle covered.
          </p> */}
        </div>

        {/* third comp */}
        <WhyChoose />

        {/* this is the fourth section */}
        <section className="bg-gray-50 ">
          <div className="container mx-auto px-6 lg:px-8">
            <h2 className="text-3xl font-bold mb-6 text-center textcolor">
              Caring for Pets. Caring for You.
            </h2>
            <p className="text-gray text-center mb-8">
              Whether it’s a pampering grooming session or expert dog training
              for a well-mannered companion, DearPet has everything your pet
              needs to thrive.
            </p>

            {/* Reviews section is here  */}
            <div className="reviews-container">
              <div className="reviews">
                {currentReviews.map((review, index) => (
                  <div key={index} className="review-card">
                    <img
                      src={review.image}
                      alt={review.name}
                      className="review-image"
                    />
                    <div className="review-content">
                      <h3 className="review-name">{review.name}</h3>
                      <p className="review-role">{review.role}</p>
                      <p className="review-text">{review.review}</p>
                      <div className="review-stars">
                        {[...Array(review.stars)].map((_, i) => (
                          <FaStar key={i} className="star-icon" />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination">
                <button
                  onClick={handlePrev}
                  disabled={currentPage === 0}
                  className="prev-btn"
                >
                  Previous
                </button>
                <br />
                <button
                  onClick={handleNext}
                  disabled={currentPage === totalPages - 1}
                  className="next-btn"
                >
                  Next
                </button>
              </div>
            </div>
            {/* ends here  */}
            <p className="text-gray-600 text-center mt-12 max-w-screen-md mx-auto">
              DearPet is every pet lover’s trusted partner—right after your pet,
              of course. With dedicated customer support and the largest network
              of pet care professionals and pet parents in India, DearPet makes
              it simple for you to find and book reliable, compassionate pet
              care services right at home.
            </p>
          </div>
        </section>
      </div>
    </>
  );
}
