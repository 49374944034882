import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Splash from "./splash";
import Home from "./Home/Home";
import AddPet from "./AddPet/AddPet";
import AddPetData from "./AddPet/AddPetData";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import TimeSlot from "./TimeSlot/TimeSlot";
import MyOrder from "./MyOrder/MyOrder";
import Login from "./Login/Login";
import Profile from "./Profile/Profile";
import Address from "./Address/Address";
import Header from "../component/Header/Header";
import PlanConfirm from "./PlanConfirm/PlanConfirm";
import MyBookings from "./MyBookings/MyBookings";
import BookedPlan from "./BookedPlan/BookedPlan";
import ReturnRefundPolicy from "./Return&Refund/ReturnRefundPolicy";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TernsCondition from "./TermsCondition/TernsCondition";
import Mypets from "./MyPets/Mypets";
import EditPet from "./EditPet/EditPet";
import ThankYou from "./ThankYou/ThankYou";
import { getBreedList } from "../service/api";
import Grooming from "./Grooming/Grooming";
import PersonalDetailsData from "./PersonalDetails/PersonalDetailsData";
import AddressDetails from "./PersonalDetails/AddressDetails";
import SummerySlot from "./TimeSlot/SummerySlot";
import PackageConfirmation from "./PlanConfirm/PackageConfirmation";
import WhatsAppIcon from "../../src/assets/icons/whatsapp3.png";
import BlogScreen from "./Blogs/BlogScreen";
import BlogListScreen from "./Blogs/BlogListScreen";
import Register from "./Register/Register";

// I added new pages from here 
import AboutUs from "./AboutUs/AboutUs";
import VetAtHome from "./VetAtHome/VetAtHome";
import PetGrooming from "./PetAtGrooming/PetGrooming";
import SlugChecker from "./Blogs/SlugChecker ";
// import ComingBlogs from "./ComingBlogs/Blogs";


import BecomingPartner from "./BecomeAPartner/BecomeAPartner";
import RedirectionHandler from "./Redirect/RedirectionHandler";


// it is ending here 
// this is for vet service import 
import Vets from "./Vets/Vets";
import DogService from "./DogServices/DogServices";
import CatService from "./CatServices/CatServices";

function Main() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Listen for resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, []);

  const showHeader = !location.pathname.includes("/address");

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loggedin"));
    if (loginData) {
      checkApi();
    } else {
    }
  }, [location]);

  const checkApi = async () => {
    try {
      const checkToken = await getBreedList();

      if (checkToken.message === "Unauthenticated.") {
        localStorage.clear();
        window.location.href = "/";
      }
    } catch (error) {
      console.log("Unauthenticated", error);
    }
  };

  const renderWhatsAppButton = () => {
    if (location.pathname === "/" && isMobile) {
      <button className="whatsapp-icon-sticky">
        <a href="https://wa.me/+917599975888" target="_blank" rel="noreferrer">
          <img src={WhatsAppIcon} alt="WhatsApp" />
        </a>
      </button>;
    }
  };
  
  return (
    <React.Fragment>
      <RedirectionHandler/>
      {showHeader && <Header />
      
      }

      <Routes>
        {/* <Route path='/' element={<Splash />} /> */}
        <Route path="" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="addpet" element={<AddPet />} />
        <Route path="register" element={<Register />} />
        <Route path="personal-details" element={<PersonalDetailsData />} />
        <Route path="appointment-details" element={<AddressDetails />} />
        <Route path="timeSlot" element={<TimeSlot />} />
        <Route path="myorder" element={<MyOrder />} />
        <Route path="editProfile" element={<Profile />} />
        <Route path=":city" element={<Grooming />} />

        
        {/* <Route path="blogs/:slug" element={<BlogScreen />} /> */}
        <Route path="blog/dear-pet" element={<BlogListScreen />} />
        <Route path="/blog/:slug" element={<BlogScreen />}/>

        <Route path="/blog/dear-pet/:slug" element={<SlugChecker />} />

        {/* <Route path="/blog/dear-pet/:slug" element={<BlogScreen />}/> */}
        
        <Route path="address" element={<Address />} />
        <Route path="planconfirm" element={<PlanConfirm />} />
        <Route path="mybookings" element={<MyBookings />} />
        <Route path="bookedplan" element={<BookedPlan />} />
        <Route path="rr-policy" element={<ReturnRefundPolicy />} />
        <Route path="p-policy" element={<PrivacyPolicy />} />
        <Route path="t-policy" element={<TernsCondition />} />
        <Route path="mypets" element={<Mypets />} />
        <Route path="package-confirmation" element={<PackageConfirmation />} />
        <Route path="editpet" element={<EditPet />} />
        <Route path="thankyou" element={<ThankYou />} />  
        
        {/*  I added new links from here */}         
        <Route path="aboutus" element={<AboutUs />} />   
        <Route path="pet-grooming" element={<PetGrooming />} />   
        <Route path="becoming-partner" element={<BecomingPartner />} />  
         {/*this added later for vets services */}

        <Route path="vet-at-home" element={<VetAtHome />} />   
        <Route path="/dog-services" element={<DogService />} />
        <Route path="/cat-services" element={<CatService />} />
      </Routes>
      
      {renderWhatsAppButton()}
      {/* {(location.pathname !=="/" && isMobile) ?? <button className="whatsapp-icon">
            <a href="https://wa.me/+917599975888" target="_blank" rel="noreferrer">
              <img src={WhatsAppIcon} alt="WhatsApp" />
            </a>
          </button>} */}
    </React.Fragment>
  );
}

export default Main;
