import React, { useEffect, useRef, useState } from "react";
import dogIocn from "../../assets/icons/mdi-dog.svg";
import catIocn from "../../assets/icons/mdi-cat.svg";
import dogDarkIocn from "../../assets/icons/mdi-dark-dog.svg";
import catDarkIocn from "../../assets/icons/mdi-dark-cat.svg";
import DropdownIcon from "../../assets/icons/location-icon.svg";
import { getAddress, getCities, saveAddress } from "../../service/api";
import SaveIcon from "../../assets/icons/save.png";
import EditIcon from "../../assets/icons/edit.png";
import swal from "sweetalert";
import AerrowBottom from "../../assets/icons/aerrow-bottom.svg";
import Dot from "../../assets/icons/grayDot.svg";
import { useNavigate } from "react-router-dom";
import { getProductPlans } from "../../service/api";
import StarIcon from "../../assets/icons/star.svg";
import StarGreyIcon from "../../assets/icons/star-grey.svg";
import { ThreeDots } from "react-loader-spinner";
import MobilePaw from "../../assets/icons/paw-mb-select.svg";
import PcPaw from "../../assets/icons/paw-pc-select.svg";
import SinglePaw from "../../assets/icons/SinglePaw.svg";
import PlansDoodle from "../../assets/icons/plans-doodle.svg";
import RightCheck from "../../assets/icons/rightCheck.svg";
import BookingPlanCard from "../../component/Card/BookingPlanCard";

const SelectCity = ({ onCityChange }) => {
  const [cities, setCities] = useState([]);
  const [selectedPet, setSelectedPet] = useState("Dog");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityId, setSelectedCityId] = useState();
  const [selectedStateId, setSelectedStateId] = useState();

  const [userAddress, setUserAddress] = useState("");
  const [userPincode, setUserPincode] = useState("");
  const [userAddId, setUserAddId] = useState();

  const [inputDisable, setInputDisable] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [loggedUserData, setLoggedUserData] = useState();

  const navigate = useNavigate();
  const [bookingPlans, setBookingPlans] = useState([]);
  const [planLoader, setPlanLoader] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setIsMobile(window.innerWidth < 768);
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    if (isLogin) {
      getUserAddress();
    }
  }, [isLogin]);

  useEffect(() => {
    const loginuser = JSON.parse(localStorage.getItem("loggedin"));
    const loginUserData = JSON.parse(localStorage.getItem("userIdData"));
    if (loginuser) {
      setIsLogin(loginuser);
    }
    if (loginUserData) {
      setLoggedUserData(loginUserData);
    }
  }, []);

  const fetchCities = async () => {
    try {
      const cityData = await getCities();

      // const sortedCities = cityData.data.sort((a, b) => a.sorting - b.sorting);

      // let addselectCity = [{ id: '-1', title: 'Select City', state_id: 13, sorting: '0' }, ...sortedCities]

      // setCities(sortedCities);
      // setCities(addselectCity);
      setCities(cityData.data);

      if (cityData.success === "1") {
        const storedItem = JSON.parse(localStorage.getItem("homeData"));
        if (storedItem) {
          setSelectedCity(storedItem.selectedCity);
          setSelectedPet(storedItem.selectedPet);
          setSelectedCityId(storedItem.selectedCityId);
          setSelectedStateId(storedItem.selectedCity.state_id);
          onCityChange(storedItem);
        } else {
          // setSelectedCity(cityData.data[0]);
          // setSelectedStateId(cityData.data[0].state_id);
          // setSelectedCityId(cityData.data[0].id);

          // setSelectedCity(cityData.data[0]);
          // setSelectedCityId(cityData.data[0].id);
          // setSelectedPet(selectedPet);

          const data = {
            selectedCity: cityData.data[1],
            selectedCityId: cityData.data[1].id,
            selectedPet: selectedPet,
          };

          // const data = {
          //   selectedCity: addselectCity[0],
          //   selectedCityId: addselectCity[0].id,
          //   selectedPet: selectedPet,
          // };

          // Store the default values in localStorage
          localStorage.setItem("homeData", JSON.stringify(data));

          // Retrieve stored item and update state
          const storedItem = JSON.parse(localStorage.getItem("homeData"));
          setSelectedCity(storedItem.selectedCity);
          setSelectedPet(storedItem.selectedPet);
          setSelectedCityId(storedItem.selectedCityId);
          setSelectedStateId(storedItem.selectedCity.state_id);

          // Call onCityChange with the desired data structure
          const onCityChangeData = {
            selectedCity: storedItem.selectedCity,
            selectedCityId: storedItem.selectedCityId,
            selectedPet: storedItem.selectedPet,
          };
          onCityChange(onCityChangeData);
        }
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const getUserAddress = async () => {
    try {
      const addressData = await getAddress();
      if (addressData?.data?.length > 0) {
        setSelectedStateId(addressData.data[0].state_id);
        setSelectedCityId(addressData.data[0].city_id);
        setUserAddress(addressData.data[0].address);
        setUserPincode(addressData.data[0].pincode);
        setUserAddId(addressData.data[0].id);
        setInputDisable(true);

        const mycityData = {
          id: addressData.data[0].id,
          state_id: addressData.data[0].state_id,
          title: addressData.data[0].city_name,
        };

        let userIdData = JSON.parse(localStorage.getItem("userIdData"));
        userIdData = {
          ...userIdData,
          selectedCity: mycityData,
          city: addressData.data[0].city_name,
          selectedCityId: addressData.data[0].city_id,
        };

        localStorage.setItem("userIdData", JSON.stringify(userIdData));

        const data = {
          selectedCity: mycityData,
          selectedCityId: addressData.data[0].city_id,
          selectedPet: selectedPet,
        };
        localStorage.setItem("homeData", JSON.stringify(data));
        const storedItem = JSON.parse(localStorage.getItem("homeData"));
        setSelectedCity(storedItem.selectedCity);
        setSelectedPet(storedItem.selectedPet);
        setSelectedCityId(storedItem.selectedCityId);
        setSelectedStateId(storedItem.selectedCity.state_id);
      } else {
        setInputDisable(false);
      }
    } catch (error) {
      console.log("city get address error", error);
    }
  };

  const onPetChange = (pettype) => {
    setSelectedPet(pettype);
    const selectedPet = pettype;

    const data = { selectedCity, selectedCityId, selectedPet };
    localStorage.setItem("homeData", JSON.stringify(data));
    const storedItem = JSON.parse(localStorage.getItem("homeData"));
    setSelectedCity(storedItem.selectedCity);
    setSelectedPet(storedItem.selectedPet);
    setSelectedCityId(storedItem.selectedCityId);

    const updatedData = {
      ...storedItem,
      inputVal: inputDisable,
    };
    onCityChange(updatedData);
  };

  const withoutLoginChangeCity = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = cities.find((city) => city.id === selectedCityId);

    setSelectedCityId(selectedCityId);
    setSelectedCity(selectedCity.title);

    const data = { selectedCity, selectedCityId, selectedPet };
    localStorage.setItem("homeData", JSON.stringify(data));
    const storedItem = JSON.parse(localStorage.getItem("homeData"));
    setSelectedCity(storedItem.selectedCity);
    setSelectedPet(storedItem.selectedPet);
    setSelectedCityId(storedItem.selectedCityId);
    setSelectedStateId(storedItem.selectedCity.state_id);
    onCityChange(storedItem);
  };

  // ---------------------------
  useEffect(() => {
    fetchPlans();
  }, [selectedCityId, selectedPet]);

  const fetchPlans = async () => {
    try {
      const bodySend = {
        category_id: "1",
        city_id: selectedCityId,
        isMultipleCity: true,
        pet: selectedPet,
      };
      const planData = await getProductPlans(bodySend);
      const order = ["Basic", "Basic + Hygiene", "Essential", "Advance"];
      const plans =
        planData.data &&
        planData?.data.sort((a, b) => {
          const getOrderIndex = (title) => {
            for (let i = 0; i < order.length; i++) {
              if (title.includes(order[i])) {
                return i;
              }
            }
            return -1;
          };

          const aIndex = getOrderIndex(a.title);
          const bIndex = getOrderIndex(b.title);

          if (aIndex === -1 && bIndex === -1) return 0;
          if (aIndex === -1) return 1;
          if (bIndex === -1) return -1;

          return aIndex - bIndex;
        });

      setBookingPlans(plans);
      // setBookingPlans(planData.data);
      if (planData.data.length !== 0 && planData.success === "1") {
        setPlanLoader(false);
      }
    } catch (error) {
      // console.error("Error fetching cities:", error);
    }
  };
  
  const handleBookNow = (plan) => {
    localStorage.setItem("selectedPlan", JSON.stringify(plan));
    if (isLogin) {
      // window.scrollTo(0, 0);
      navigate("/mypets");
      // window.location.href ="/mypets";
    } else {
      // window.scrollTo(0, 0);
      navigate("/personal-details");
      // window.location.href ="/personal-details";
    }
  };

  return (
    <div className="mainBookingContainer">
      <div className="cityMain mt-2" id="selectecitySection">
        <div className="cityGroomingWrapper">
          <div className="dearpetGroomPlan">

            <div className="parent-container">
              <h3 className="dearPetPlans">DearPet Grooming Packages</h3>
            </div>
          </div>


          <div className="selectCityPart">
            <h5 className="sec2Headingcity mb-1">Please select your city</h5>
            <select
              // className={`cityDropdown1 ${inputDisable ? "muteInput" : ""}`}
              className="cityDropdown1"
              onChange={withoutLoginChangeCity}
              value={selectedCityId}
            >
              {cities.map((city) =>
                city.id == -1 ? (
                  <option key={city.id} value={city.id} disabled={true}>
                    {city.title}
                  </option>
                ) : (
                  <option key={city.id} value={city.id}>
                    {city.title}
                  </option>
                )
              )}
            </select>
          </div>

          <div className="selectPetDiv">
            <button
              className={
                "petBtn " + (selectedPet === "Dog" ? "petBtnActive" : "")
              }
              onClick={() => {
                // setSelectedPet("Dog");
                onPetChange("Dog");
              }}
            >
              <img
                src={selectedPet === "Dog" ? dogIocn : dogDarkIocn}
                className="me-2"
                alt="Dog"
              />
              Dogs{" "}
            </button>
            <button
              className={
                "petBtn " + (selectedPet === "Cat" ? "petBtnActive" : "")
              }
              onClick={() => {
                // setSelectedPet("Cat");
                onPetChange("Cat");
              }}
            >
              <img
                src={selectedPet === "Cat" ? catIocn : catDarkIocn}
                className="me-2"
                alt="Cat"
              />
              Cats{" "}
            </button>
          </div>
        </div>
      </div>

      <div className="bookingPlansWrapper">
        <h1 className="packageConfirmTitle homePackageTitle mt-3 text-center">
          <span style={{ color: "#F29A4C" }}>{selectedCity?.title ?? ""}</span>{" "}
          Packages
        </h1>
        <div className="city-botom-txt mb-4">
          <img src={StarGreyIcon} alt="star" className="me-1 pb-1" />
          <span className="citytext">
            Prices may vary according to your City and Pet
          </span>
        </div>

        {planLoader && (
          <div className="d-flex justify-content-center">
            <ThreeDots color="#7B5BFB" height={50} width={50} />
          </div>
        )}

        {!planLoader && (
          <div
            className="row justify-content-center bookingCardContainer mt-2"
            style={{ margin: "0 auto" }}
          >
            {bookingPlans.map((plan, index) => (
              <BookingPlanCard
                key={index}
                plan={plan}
                index={index}
                length={bookingPlans.length}
                isMobile={isMobile}
                handleBookNow={handleBookNow}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectCity;
