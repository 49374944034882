import React from "react";
import { Swiper, SwiperSlide  } from "swiper/react";
import { Pagination } from "swiper/modules";

import "./style.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import Before1Img from "../../assets/before-after/Before 1.jpeg";
import Before2Img from "../../assets/before-after/before 2.jpeg";
import Before3Img from "../../assets/before-after/before 3.jpeg";
import Before4Img from "../../assets/before-after/before 4.jpeg";
import Before5Img from "../../assets/before-after/before 5.jpeg";

import After1Img from "../../assets/before-after/After 1.jpeg";
import After2Img from "../../assets/before-after/after 2.jpeg";
import After3Img from "../../assets/before-after/after 3.jpeg";
import After4Img from "../../assets/before-after/after 4.jpeg";
import After5Img from "../../assets/before-after/after 5.jpeg";

// SwiperCore.use([Navigation]);
const Gallery = () => {
  const gallery = [
    {
      id: 1,
      before: Before1Img,
      after: After1Img,
    },
    {
      id: 2,
      before: Before2Img,
      after: After2Img,
    },
    {
      id: 3,
      before: Before3Img,
      after: After3Img,
    },
    {
      id: 4,
      before: Before4Img,
      after: After4Img,
    },
    {
      id: 5,
      before: Before5Img,
      after: After5Img,
    },
  ];

  return (
    <div className="container my-2 mt-4">
      <h4 className="gallery-heading text-center">Gallery</h4>
      <div className="row mt-3">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {gallery.map((myimg) => {
            return (
              <SwiperSlide key={myimg.id}>
                <div className="galleryImgBody">
                  <div className="text-overlay txtbefore">
                    <p className="overlay-text">BEFORE</p>
                  </div>
                  <img src={myimg.before} alt="before" className="gallryImg beforeimg" />
                  <div className="text-overlay txtafter">
                    <p className="overlay-text">AFTER</p>
                  </div>
                  <img src={myimg.after} alt="after" className="gallryImg afterimg" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Gallery;
