import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Main from "./pages/Main";
import { useEffect, useState } from "react";
import Splash from "./pages/splash";
import Header from "./component/Header/Header";
import Footer from "./component/Footer/Footer";
import axios from "axios";
import Swal from "sweetalert2";
import WhatsAppIcon from "../src/assets/icons/whatsapp3.png";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCY41jn0eEZMsfbNzEyZiLMwsS2r5yFT7k",
  authDomain: "dearpet-23e75.firebaseapp.com",
  projectId: "dearpet-23e75",
  storageBucket: "dearpet-23e75.appspot.com",
  messagingSenderId: "189601290532",
  appId: "1:189601290532:web:486e3895b3457163267bb1",
  measurementId: "G-JMG2W0LG5E"
};



// const firebaseConfig = {
//   apiKey: "AIzaSyC3cMy3PIM-1pnM2ackG4Cv3u_gWlt0uH0",
//   authDomain: "dearpet-testing.firebaseapp.com",
//   projectId: "dearpet-testing",
//   storageBucket: "dearpet-testing.appspot.com",
//   messagingSenderId: "454156463581",
//   appId: "1:454156463581:web:9f34aa89313a655883da34",
//   measurementId: "G-ZTKY9D7J4M"
// };

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

function App() {
  const [load, setLoad] = useState(true);
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1000);

    analytics.logEvent("page_view");
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        {/* {load ? <Splash /> :<Main />} */}
        {/* <Header /> */}
        <Routes>
          <Route path="/*" element={<Main />} />
        </Routes>
        <Footer />         
      </BrowserRouter>
    </div>
  );
}

export default App;
