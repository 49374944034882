import React, { useEffect, useState } from 'react'
import LeftImg from "../../assets/images/Mobile login-bro.svg";
import LogoIcon from "../../assets/icons/logo.svg";
import PhoneInput from 'react-phone-input-2';
import OtpInput from "react-otp-input";
import { activeAccount, sendOtp, userDetails } from '../../service/api';
import { setAuthToken } from '../../service/apiService';
import Swal from 'sweetalert2';

function Register() {
    const [fname, setFname] = useState("");
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [countryCode, setCountryCode] = useState("+91");
    const [mobile, setMobile] = useState("");
    const [fnameError, setFNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [otpBtnState, setOtpBtnState] = useState(false);
    const [loginApiCall, setLoginApiCall] = useState();
    const [otpSendSts, setOtpSendSts] = useState(false);
    const [otpState, setOtpState] = useState(false);
    const [verified, setVerified] = useState(false);
    const [otpError, setOtpError] = useState("");
    const [nationalNumber, setNationalNumber] = useState();
    const [authTokens, setAuthTokens] = useState();
    const [phoneTouched, setPhoneTouched] = useState(false);

    useEffect(() => {
        const verifyOtpandActive = async () => {
            try {
                const bodySend = {
                    otp: otp,
                    username: nationalNumber,
                };

                const verifyData = await activeAccount(bodySend);

                setAuthToken(verifyData.data.token);
                setAuthTokens(verifyData.data.token);
                localStorage.setItem("authToken", verifyData.data.token);
                if (verifyData.success === "0") {
                    setOtpError("Invalid Otp");
                }

                if (verifyData.status === "200") {
                    setOtpError("");
                    setVerified(true);
                    setOtpState(false);
                    const existingData = JSON.parse(localStorage.getItem("userIdData"));
                    if (existingData) {
                        existingData.verified = true;
                        localStorage.setItem("userIdData", JSON.stringify(existingData));
                    }
                }
            } catch (error) {
                console.error("Error in verifying OTP:", error);
            }
        };


        if (otp.length === 6) {
            // if(loginApiCall === '0'){
            //   LoginApi();
            // }
            // else{
            //   verifyOtpandActive();
            // }
            verifyOtpandActive();
        }
    }, [otp, nationalNumber]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (authTokens) {
            try {

                localStorage.setItem("loggedin", "true");
                window.location.href = "/"

            } catch (error) {

            }

        }
    };

    const onSendOtp = async () => {
        if (validateFields()) {
            try {
                const bodySend = {
                    user_type: "Customer",
                    name: fname,
                    email: email,
                    country_code: "+" + countryCode,
                    phone_number: nationalNumber,
                    password: "123456",
                    gender: "Male",
                    dob: "1993-12-06",
                    otp: otp,
                };
                const userData = await userDetails(bodySend);

                setLoginApiCall(userData.success);

                if (userData.success === "1") {
                    try {
                        const bodySend = {
                            username: nationalNumber,
                        };
                        const userData = await sendOtp(bodySend);
                        if (userData.success === "1") {
                            setOtpState(true);
                            setOtpSendSts(true);
                        }
                    } catch (error) {
                        console.error("Error in send Otp:", error);
                    }

                    const combinedData = {
                        ...bodySend,
                        ...userData.data,
                        verified: verified,
                        fname: fname,
                    };
                    localStorage.setItem("userIdData", JSON.stringify(combinedData));
                } else if (userData.status === "201") {
                    if (userData.message === "Phone Number Already Exist") {
                        const bodySend = {
                            username: nationalNumber,
                        };
                        const userData = await sendOtp(bodySend);
                        if (userData.success === "1") {
                            setOtpState(true);
                            setOtpSendSts(true);
                        }
                        const combinedData = {
                            ...bodySend,
                            ...userData.data,
                            verified: verified,
                            fname: fname,
                            email: email,
                            country_code: "+" + countryCode,
                        };
                        localStorage.setItem("userIdData", JSON.stringify(combinedData));

                    } else {
                        Swal.fire({
                            title: "",
                            text: userData.message,
                            icon: "error",
                        });
                    }

                }
            } catch (error) {
                console.error("Error in Save user:", error);
            }
        }
    };

    const handlePhoneChange = (value, country, e, formattedValue) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.slice(countryCode.length);

        setMobile(value);

        setCountryCode(countryCode);
        setNationalNumber(phoneNumber);
        setPhoneError("");
        // setMobile(val);
        // const phoneNumber = parsePhoneNumber(val);
        // const nationalNumber = phoneNumber ? phoneNumber.nationalNumber : "";
        // console.log("phn", phoneNumber);
        // console.log("nationalNumber", nationalNumber);
        // setNationalNumber(nationalNumber);
        // const countryCode = phoneNumber ? phoneNumber.countryCallingCode : "+91";
        // console.log("countrycode ", countryCode);
        // setCountryCode(countryCode);
        setPhoneTouched(true);
    };


    const validateFields = () => {
        let isValid = true;
        // Validate Name
        if (!fname) {
            setFNameError(" Name is required.");
            isValid = false;
        } else if (fname.length < 2 || fname.length > 99) {
            setFNameError("Name must be between 3 and 99 characters.");
            isValid = false;
        } else {
            setFNameError("");
        }

        if (!email) {
            setEmailError("Email is required.");
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            setEmailError("Invalid email address.");
            isValid = false;
        } else {
            setEmailError("");
        }

        // Validate Phone
        if (!nationalNumber) {
            setPhoneError("Phone number is required.");
            isValid = false;
        } else {
            setPhoneError("");
        }


        return isValid;
    };

    return (
        <div className="container my-5">
            <div className="row justify-content-center align-items-center px-4">
                <div className="col-md-6 d-none d-lg-block">
                    <img src={LeftImg} alt="img" />
                </div>
                <div className="col-md-6 text-center">
                    <img src={LogoIcon} alt="Logo" />
                    <h5 className="text-center registerTitle">Register</h5>
                    <p className="text-center">Welcome Back!</p>

                    <form className="my-4">
                        <div className="mb-3 formR px-3 inputWrapper">
                            <label htmlFor="name" className="inputLabel">Name</label>
                            <input
                                type="text"
                                className={`inputFormControl ${fnameError ? "is-invalid" : ""}`}
                                name="name"
                                placeholder="Full Name"
                                onChange={(e) => {
                                    setFname(e.target.value);
                                    setFNameError("");
                                }}
                                value={fname}
                            />
                        </div>
                        <div className="mb-3 px-3 inputWrapper">

                            {fnameError && (
                                <div className="invalid-feedback">{fnameError}</div>
                            )}
                        </div>


                        <div className="mb-3 px-3 inputWrapper">
                            <label htmlFor="email" className="inputLabel">Email</label>
                            <input
                                type="email"
                                className={`inputFormControl ${emailError ? "is-invalid" : ""}`}
                                name="email"
                                placeholder="Email"
                                onChange={(e) => {
                                    setEmail((e.target.value).trim());
                                    setEmailError("");
                                }}
                                value={email}
                            />
                            {emailError && (
                                <div className="invalid-feedback">{emailError}</div>
                            )}
                        </div>

                        <div className="mb-3 px-3 inputWrapper">
                            <label htmlFor="phone" className="inputLabel">Phone Number</label>
                            <div className="inputFormControl" style={{ padding: "7px 10px" }}>
                                <PhoneInput
                                    country={"in"}
                                    onlyCountries={['in']}
                                    value={mobile}
                                    onChange={handlePhoneChange}
                                    inputMode="tel"
                                    buttonStyle={{ border: "none", backgroundColor: "transparent", }}
                                />

                            </div>
                            {verified ? (
                                <p className="verifiedTextPhone">Verified</p>
                            ) : otpSendSts ? (
                                <p className="verifiedTextPhone otp-sent">Otp Sent</p>
                            ) : (
                                <input
                                    type="button"
                                    className={"sendOtpBtnPhone " + (otpBtnState ? "dis" : "")}
                                    value="Send OTP"
                                    onClick={onSendOtp}
                                />
                            )}

                        </div>
                        <div className="mb-3 px-3 inputWrapper">
                            {phoneError && (
                                <div className="invalid-feedback">{phoneError}</div>
                            )}
                        </div>

                        {otpState ? (
                            <div className="mb-3 px-3 otp-wrapper">
                                <label className="otpLabel">Enter OTP Here</label>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={<span></span>}
                                    renderInput={(props) => <input {...props} inputMode="tel" />}
                                />
                                {otpError && <div className="invalid-feedback">{otpError}</div>}
                            </div>
                        ) : (
                            <></>
                        )}


                        <div className="mb-3 px-3 text-center">
                            <button
                                type="submit"
                                className={
                                    "btn nextBtn text-center " + (verified ? "" : "disabled")
                                }
                                onClick={handleSubmit}
                            >
                                Sign Up
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Register