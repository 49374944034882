import React from "react";
import PlusQ from "../../assets/icons/plusQ.svg";
import CloseQ from "../../assets/icons/closeQ.svg";

const FAQ = () => {
  return (
    <div className="container py-4">
      <h4 className="sec4Heading my-4 text-start">
        Frequently asked questions
      </h4>

      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="row">
          <div className="col-md-6"> {/* This div represents the first column */}
            <div className="accordion-item my-1">
              <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  className="accordion-button faq_Q collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq1"
                  aria-expanded="true"
                  aria-controls="faq1"
                >
                  Do you bring a van or vehicle ?
                </button>
              </h2>
              <div
                id="faq1"
                className="accordion-collapse collapse  "
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div className="accordion-body faqAnsBody text-start">
                  We don't bring a Van or vehicle. We provide an At - Home Pet
                  Grooming Service. We require a free space like a bathroom,
                  balcony or terrace with a water and electricity connection.
                  Groomers carry all the products and equipment. We do ensure
                  that the grooming area is properly cleaned before we leave
                  your house
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6"> {/* This div represents the second column */}
            <div className="accordion-item my-1">
              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  className="accordion-button faq_Q collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq2"
                  aria-expanded="true"
                  aria-controls="faq2"
                >
                  Will you clean the area ?
                </button>
              </h2>
              <div
                id="faq2"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div className="accordion-body faqAnsBody text-start">
                  Cleanliness is our priority. We do ensure that the grooming
                  area is properly cleaned before we leave your house.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6"> {/* This div represents the third column */}
            <div className="accordion-item my-1">
              <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  className="accordion-button faq_Q collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq3"
                  aria-expanded="true"
                  aria-controls="faq3"
                >
                  Will you bring your own equipment ?
                </button>
              </h2>
              <div
                id="faq3"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div className="accordion-body faqAnsBody text-start">
                  Groomers carry all the necessary products and tools,
                  including shampoo, conditioner, and grooming equipment, all of
                  which are thoroughly sanitized for your pet's safety and
                  comfort.
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6"> {/* This div represents the fourth column */}
            <div className="accordion-item my-1">
              <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                <button
                  className="accordion-button faq_Q collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq4"
                  aria-expanded="true"
                  aria-controls="faq4"
                >
                  Can I pay after the grooming session ?
                </button>
              </h2>
              <div
                id="faq4"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingFour"
              >
                <div className="accordion-body faqAnsBody text-start">
                  Absolutely! We offer the flexibility to pay for your grooming
                  session both before and after the appointment. We accept
                  various payment methods, including credit cards, debit cards,
                  Paytm, PhonePe, and Google Pay. You can choose the payment
                  option that best suits your convenience.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
