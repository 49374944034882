import React, { useEffect, useState } from "react";
import {
  getAddress,
  getProductPlans,
  getServiceDetails,
} from "../../service/api";
import "./style.css";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import EditIcon from "../../assets/icons/Edit.svg";
import Modal from "react-modal";
import AerrowBottom from "../../assets/icons/aerrow-bottom.svg";
import StarIcon from "../../assets/icons/star.svg";
import CrossIcon from "../../assets/icons/cross.svg";
import Swal from "sweetalert2";

const PlanConfirm = () => {
  const navigate = useNavigate();
  const [planId, setPlanId] = useState();
  const [selectedPlanData, setSelectedPlanData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [localUAddress, setLocalUAddress] = useState();
  const [uAddress, setUAddress] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [bookingPlans, setBookingPlans] = useState([]);
  const [planLoadingHeader, setPlanLoadingHeader] = useState(true);

  const customStyles = {
    content: {
      width: '70%',  // Default width for larger screens
      maxHeight: '60vh',
      margin: 'auto',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };
  const mobileStyles = {
    content: {
      maxHeight: '80vh',
      width: '95%',  // Adjusted width for mobile screens
      top: '10%',    // Inset by 25%
      left: '2.5%',  // Center horizontally
      right: '2.5%',
      bottom: '10%',
    },
  };

  useEffect(() => {
    const localCity = JSON.parse(localStorage.getItem("homeData"));
    setLocalUAddress(localCity);
  }, []);

  useEffect(() => {
    getServiceData();
    getUserAddress();
    // getAllPlans();
  }, []);

  const getServiceData = async () => {

    try {
      const plandata = JSON.parse(localStorage.getItem("selectedPlan"));
      const localAdd = JSON.parse(localStorage.getItem("homeData"));

      if (plandata) {
        setPlanId(plandata.id);
      }
      const serviceData = await getServiceDetails(
        plandata.id,
        localAdd.selectedCityId
      );
      if (serviceData.success === "1") {
        setIsLoading(false);
        if (serviceData.data[0]) {
          setSelectedPlanData(serviceData.data[0]);
        }
      }
    } catch (error) {
      console.log("Error on Get ServiceData", error);
    }
  };

  const getUserAddress = async () => {
    try {
      const userAddData = await getAddress();
      if (userAddData.data[0]) {
        setUAddress(userAddData.data[0]);
      }
    } catch (error) {
      console.log("Error on get User address", error);
    }
  };

  const getAllPlans = async () => {
    try {
      const bodySend = {
        category_id: "1",
        city_id: localUAddress.selectedCityId,
        isMultipleCity: true,
        pet: localUAddress.selectedPet,
      };
      const planData = await getProductPlans(bodySend);
      setBookingPlans(planData.data);
      setPlanLoadingHeader(false);
    } catch (error) {
      console.log("Error on get palns", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (localUAddress.selectedCityId === uAddress.city_id) {
      navigate("/timeSlot");
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    } else if (uAddress.length === 0) {
      swal({
        title: "",
        text: "Please Add Your Address",
        icon: "error",
      }).then((confirm) => {
        if (confirm) {
          navigate("/editProfile", { state: { frompage: "confirm" } });
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 500);
        } else {
          setTimeout(() => {
            navigate("/editProfile", { state: { frompage: "confirm" } });
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 500);
          }, 1000);
        }
      });
    } else {
      swal({
        title: "",
        text: "Your SelectedCity and AddressCity not match, Please select your plan according to Address",
        icon: "error",
      }).then((confirm) => {
        if (confirm) {
          navigate("/editProfile", { state: { frompage: "confirm" } })
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 500);
        } else {
          setTimeout(() => {
            navigate("/editProfile", { state: { frompage: "confirm" } })
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 500);
          }, 1000);
        }
      });
    }
  };

  const onEditAddressMove = () => {
    navigate("/editProfile", { state: { frompage: "confirm" } });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const onEditPlanMove = () => {
    // window.scrollTo(0, 0);
    // navigate("/");
    getAllPlans();
    Swal.fire({
      title: "Are you sure you want to change your package?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7B5BFB",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsModalOpen(true);
      }
    });
  };

  const handleChangeBookNow = async (plan) => {
    localStorage.setItem("selectedPlan", JSON.stringify(plan));
    setSelectedPlanData(plan);
    setIsModalOpen(false);
    window.scrollTo(0, 0);
    window.location.reload();
  };

  const handleModalClose = async => {
    setIsModalOpen(false);
  }

  return (
    <div className="container mt-2 mb-4 plan-con-container">
      <div className="row justify-content-center">
        {isLoading && (
          <div className="d-flex justify-content-center mt-5">
            <ThreeDots color="#7B5BFB" height={50} width={50} />
          </div>
        )}

        {!isLoading && (
          <div className="col-md-8 pb-3">
            {/* <img
              src={selectedPlanData.image}
              alt="img"
              className="serviceImg img-responsive"
            />
            <hr /> */}
            <div className="top-details">
              <p className="plan-title m-0">{selectedPlanData?.title}</p>
              <p className="pln-offerPrice m-0">
                ₹ {selectedPlanData?.offer_price}{" "}
                <span className="defultPrice">
                  <s>₹ {selectedPlanData?.price}</s>
                </span>
              </p>
            </div>
            <div className="top-details mt-2">
              {/* <p className="ab-title m-0">About Package</p> */}
              <div className="details-header">
                <p className="ab-title m-0">About Package</p>
                <img
                  src={EditIcon}
                  alt="edit"
                  className="editicon"
                  onClick={onEditPlanMove}
                />
              </div>
              <p className="pln-b-title">Includes</p>
              <ul className="planDescList planDescList2">
                {selectedPlanData?.description &&
                  selectedPlanData?.description.split("\r\n").map((line, subIndex) => (
                    <React.Fragment key={subIndex}>
                      {line.split('\n').map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </React.Fragment>
                  ))}
              </ul>

              {selectedPlanData?.cancellation_and_refund_policy !== "" ? (
                <>
                  <p className="pln-b-title">Cancellation and refund policy</p>
                  <ul className="planDescList planDescList2">
                    <li>{selectedPlanData?.cancellation_and_refund_policy}</li>
                  </ul>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="top-details mt-2">
              <div className="details-header-add">
                <img
                  src={EditIcon}
                  alt="edit"
                  className="editicon"
                  onClick={onEditAddressMove}
                />
              </div>
              <div className="AddressDiv">
                <div className="leftCity">
                  <p className="ab-title">Your Selected City</p>
                  <p>{localUAddress.selectedCity.title}</p>
                </div>
                <div className="rightCity">
                  <p className="ab-title">Your Address</p>
                  <p className="m-0">{uAddress.city_name}</p>
                  <p className="m-0">{uAddress.address}</p>
                  <p className="m-0">{uAddress.pincode}</p>
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className={"btn nextBtn text-center mt-4"}
                onClick={handleSubmit}
              >
                Next
              </button>

            </div>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              style={window.innerWidth <= 600 ? { ...customStyles, ...mobileStyles } : customStyles}
            >
              <div className="r-m">
                <div className="modal-header-row">
                  <h2>Change Your Plan</h2>
                  <img src={CrossIcon} alt="cross" onClick={() => { handleModalClose() }} style={{ cursor: 'pointer' }} />
                </div>
                {planLoadingHeader ? (
                  <div className="d-flex justify-content-center mt-5">
                    <ThreeDots color="#7B5BFB" height={50} width={50} />
                  </div>
                ) : (
                  <div className="row planMainRow">
                    {bookingPlans.map((plan, index) => (
                      <div key={index} className="palnHeader my-2">
                        <div
                          className={`palnMainDiv ${index === 0 ? "" : "collapsed"
                            }`}
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseExample${index}`}
                          aria-expanded={index === 0 ? "true" : "false"}
                          aria-controls={`collapseExample${index}`}
                        >
                          <div className="planOffDiv">
                            <div>
                              <p className="planHeading text-start">
                                {plan.title}{" "}
                                {/* <img src={Dot} className="ms-1 dotImg" alt="dot" />{" "}
                                <span className="planHeadPrice ms-1">
                                  ₹ {plan.offer_price}/-
                                </span> */}
                              </p>
                              <div className="bookingReview">
                                <img
                                  src={StarIcon}
                                  alt="icon"
                                  className="me-2"
                                />
                                <p className="planReview text-start m-0">
                                  {" "}
                                  {plan.rating} ({plan.total_rating} reviews)
                                </p>
                              </div>
                            </div>
                            <div className="booking-right-Price">
                              <div className="me-2">
                                <span className="planHeadPrice">
                                  ₹ {plan.offer_price}/-
                                </span>{" "}
                              </div>
                              <img src={AerrowBottom} alt="aero" />
                            </div>
                          </div>
                        </div>
                        {/* <div className="collapse" id={`collapseExample${index}`}> */}
                        <div
                          className={`collapse ${index === 0 ? "show" : ""}`}
                          id={`collapseExample${index}`}
                        >
                          <div className="card card-body border-0 p-0 mt-3 brb">
                            <ul className="planDescList">
                              {plan.description
                                .split(/\r\n|\n/)
                                .map((item, subIndex) => (
                                  <li key={subIndex}>{item}</li>
                                ))}
                            </ul>
                            <div className="planfooter">
                              <p className="planPrice me-4">
                                ₹ {plan.offer_price}/-
                              </p>
                              <button
                                className="planBookNowBtn"
                                onClick={() => {
                                  handleChangeBookNow(plan);
                                }}
                              >
                                Book Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanConfirm;
