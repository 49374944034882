import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import "./BlogScreen.css"; // Import the CSS file

const SlugChecker = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const slugList = [
    "the-benefits-of-retort-packaging-for-dearpet-fresh-dog-food",
    "the-benefits-of-bone-broth-for-dogs-nutrition-and-health-boost",
    "the-benefits-of-feeding-fresh-dog-food-to-your-beloved-pet",
    "fresh-food-vs-kibble-unleashing-the-power-of-proper-canine-nutrition",
    "pampered-pooch-essential-tips-for-dog-grooming-at-home",
    "your-kitty-is-not-less-than-a-brainiac",
    "meowpedia-anything-and-everything-about-your-cat",
    "all-green-smoothie-popsicle-to-melt-away-your-dog-s-summers",
    "7-best-ways-to-keep-your-dog-engaged-at-home",
    "how-to-make-your-dog-s-bath-time-fun-and-healthy",
    "how-to-cool-down-your-furries-in-summers",
    "puppy-care-regime-know-everything-about-raising-one",
    "what-does-it-mean-when-a-dog-lets-you-rub-their-belly",
    "pure-mix-cross-and-stray-breed-what-makes-them-unique",
    "how-to-take-care-of-your-pet-in-the-winter-season",
    "shih-tzu",
    "siberian-husky",
    "labrador-retriever",
    "dog-breeds-and-their-traits",
    "whiskers-in-cats-and-dogs",
    "a-tick-and-flea-free-summer-for-your-pets",
    "why-is-a-rabbit-the-perfect-pet-for-your-family",
    "is-catnip-safe-for-cats",
    "neutering-spaying",
    "nutrition-in-cats",
    "vitamins-and-minerals",
    "why-should-we-avoid-feeding-sweet-products-to-dogs-and-cats",
    "fatty-acids-for-dogs",
    "carbohydrates-for-dogs",
    "nine-eatables-that-are-toxic-for-the-felines",
    "life-stages-of-dogs-their-nutritional-values",
    "puppy-adult-senior-some-interesting-traits-in-each-life-stage",
    "how-is-protein-a-powerhouse-for-dogs",
    "nourishment-of-dogs-an-overview",
    "dogs-in-the-military",
    "homosexuality-in-dogs",
    "better-ways-to-fulfil-your-furry-s-chewing-tendencies",
    "dogs-and-phobias",
    "how-to-stop-your-cat-from-scratching-furniture",
    "why-do-some-dogs-bark-at-every-noise",
    "how-does-music-help-dogs",
    "do-dogs-enjoy-watching-tv",
    "why-are-dogs-afraid-of-stairs-and-how-can-we-help-them",
    "6-signs-your-dog-is-secretly-living-with-pain",
    "five-ways-to-prepare-your-cat-for-a-new-born-baby",
    "tips-for-leaving-your-dog-in-someone-elses-care",
    "chewing-to-nipping-how-to-keep-my-pup-away-from-it",
    "sexual-frustration-in-canines",
    "5-signs-you-need-to-hire-a-dog-trainer",
    "are-you-adopting-a-cat",
    "how-to-introduce-your-pet-dog-to-a-cat",
    "how-to-manage-an-overprotective-dog",
    "5-appropriate-ways-to-raise-confidence-in-dogs",
    "does-your-dog-hate-car-rides",
    "bowls-and-feeders-how-to-choose-one-for-dogs",
    "antioxidants-for-dogs-good-or-bad",
    "is-it-ok-to-leave-my-dog-home-alone",
    "six-things-that-dogs-can-easily-sense-in-a-human",
    "dogs-and-human-babies-tips-to-introduce-them-to-each-other",
    "adopting-street-dogs",
    "bringing-a-puppy-home-first-time-at-pet-parenting",
    "why-do-dogs-respond-aggressively",
    "all-about-dog-toys-learn-which-your-pet-will-prefer",
    "a-guide-to-keep-dogs-healthy",
    "is-my-dog-ignoring-commands",
    "birds-rabbits-hamsters-everything-about-their-nutritional-feeding",
    "top-7-best-dog-food-brands-for-your-dog-s-perfect-nourishment",
    "8-best-cat-food-brands-to-give-your-cat-a-healthy-nourishment",
  ];
  useEffect(() => {
    if (slugList.includes(slug)) {
      window.location.href = `https://dearpetindia.com/blogs/dear-pet/${slug}`;
    } else {
      // Fetch blog data if slug is not in the list
      // getBlog();
    }
  }, [slug]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  const sanitizedContent = DOMPurify.sanitize(blog?.content);

  return (
    <div className="container blog-screen-container">
      <div className="back-link-container mt-16">
        <span
          className="back-link mx-2"
          onClick={() => navigate("/blog/dear-pet")}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") navigate("/blog/dear-pet");
          }}
        >
          Blogs
        </span>
        {` / ${blog?.title ?? ""}`}
      </div>
      {blog ? (
        <div className="blog-content">
          {blog.cover && (
            <img src={blog.cover} alt="Cover" className="blog-cover" />
          )}
          <h1 className="blog-title">{blog.title}</h1>
          <div
            className="blog-body"
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          />
          <p className="blog-date">
            <strong>Published on: </strong>
            {new Date(blog.created_at).toLocaleDateString()}
          </p>
        </div>
      ) : (
        <div className="not-found">Blog not found</div>
      )}
    </div>
  );
};

export default SlugChecker;
