import React, { useEffect, useState } from "react";
import "./style.css";
import CrossOrangeIcon from "../../assets/icons/CrossOrangeIcon.svg";
import TimeCircle from "../../assets/icons/Time Circle.svg";
import FootPrintIcon from "../../assets/icons/DogFootPrint.svg";
import AnimalIcon from "../../assets/icons/DogIcon.svg";
import TimeCirclecolorIcon from "../../assets/icons/ClockIcon.svg";
import PlusIcon from "../../assets/icons/Plus.svg";
import GiftboxIcon from "../../assets/icons/giftBox.svg";
import LocationIcon from "../../assets/icons/Location.svg";
import DogImg from "../../assets/images/dog.svg";
import CrossFillIcon from "../../assets/icons/closeQ2.svg";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { ThreeDots } from "react-loader-spinner";
import WalletIcon from "../../assets/icons/Wallet.svg";
import PayOnlineIcon from "../../assets/icons/iconoir_hand-cash.svg";

import {
  cancelCartItem,
  getAddress,
  getCartDataForCheckout,
  getCoupons,
  getProductPlans,
  placeOrderTest,
} from "../../service/api";
import { placeOrder } from "./../../service/api";
import { useNavigate } from "react-router-dom";

const MyOrder = () => {
  const navigate = useNavigate();

  const [cartData, setCartData] = useState([]);
  const [userAddress, setUserAddress] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState(0);
  const [couponsData, setCouponsData] = useState([]);
  const [selectedCouponsData, setSelectedCouponsData] = useState();
  const [couponApplied, setCouponApplied] = useState(false);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [cart_pet, setCart_pet] = useState("");
  const [termCondition, setTermsCondition] = useState();
  const [termsError, setTermsError] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [cartLength, setCartLength] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    getCart();
    getAddres();
    getCouponss();
  }, []);

  const getCart = async (coupon_code) => {
    const cityData = JSON.parse(localStorage.getItem("homeData"));

    const cityId = cityData.selectedCityId;

    const bodySend = {
      cityId: cityId,
      coupon_code: coupon_code,
    };

    try {
      const cartData = await getCartDataForCheckout(bodySend);
      if (cartData) {
        setIsLoading(false);
      }

      setCartData(cartData.data);
      setCartLength(cartData.data.items.length);
      localStorage.setItem("cartData", JSON.stringify(cartData.data));
      if (cartData.data.items && cartData.data.items.length > 0) {
        const petType = cartData.data.items[0].pet_type;
        // recommendProduct(petType);
        setCart_pet(petType);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error on get cart", error);
    }
  };

  const getAddres = async () => {
    try {
      const address = await getAddress();
      setUserAddress(address.data[address.data.length - 1]);
    } catch (error) {
      console.log("error on get address", error);
    }
  };

  const getCouponss = async () => {
    try {
      const bodySend = {
        catId: 1,
        isMultipleCity: true,
      };
      const coupons = await getCoupons(bodySend);
      if (coupons.success === "1") {
        setCouponsData(coupons.data);
      }
    } catch (error) {
      console.log("Error on get coupons", error);
    }
  };

  const removeFromCart = async (id) => {
    Swal.fire({
      title: "Are you sure you want to Remove Item ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7B5BFB",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const bodySend = {
            item_id: id,
          };

          const cartItem = await cancelCartItem(bodySend);
          if (cartItem.success === "1") {
            Swal.fire({
              title: "Item Remove Successfully!",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            }).then(() => {});
          }
          getCart();
        } catch (error) {
          console.log("Error on Cancle cart item ", error);
        }
      }
    });
  };

  const validate = () => {
    let isValid = true;
    if (!termCondition) {
      setTermsError("Please Check Terms & Condition");
      Swal.fire({
        title: "",
        text: "Please Check Terms & Condition",
        icon: "error",
        showConfirmButton: true,
        confirmButtonText: "OK",

        timer: 1000,
        timerProgressBar: true,
      });
      isValid = false;
    } else {
      setTermsError("");
    }

    if (!paymentMethodId) {
      setPaymentError("Select Payment Method");
      Swal.fire({
        title: "",
        text: "Select Payment Method",
        icon: "error",
        showConfirmButton: true,
        confirmButtonText: "OK",

        timer: 1000,
        timerProgressBar: true,
      });
      isValid = false;
    } else {
      setPaymentError("");
    }

    return isValid;
  };

  const placeOrderFun = async () => {
    if (cartLength > 0) {
      if (validate()) {
        setIsButtonDisabled(true);
        try {
          let newTime;
          const date = cartData.items[0].appoinment_date;
          const time = cartData.items[0].appoinment_time;

          const dateTimeString = `${date} ${time}`;
          const parsedTime = new Date(dateTimeString);
          if (!isNaN(parsedTime)) {
            // Extract hours and minutes
            const hours = parsedTime.getHours();
            const minutes = parsedTime.getMinutes();

            // Format the time to "H:i"
            const newFormattedTime = `${String(hours).padStart(
              2,
              "0"
            )}:${String(minutes).padStart(2, "0")}`;
            newTime = newFormattedTime;
          } else {
            console.error("Invalid date or time format");
          }

          const bodySend = {
            address_id: userAddress.id,
            payment_method_id: paymentMethodId,
            appoinment_date: cartData.items[0].appoinment_date,
            // appoinment_time: newTime,
            appoinment_time: time,
            coupon_code: selectedCouponsData?.id,
          };

          const orderData = await placeOrder(bodySend);

          if (orderData.success === "1") {
            Swal.fire({
              title: "Success",
              text: "Confirm Successfully",
              icon: "success",
              showConfirmButton: false,
              confirmButtonText: "OK",
              willClose: (isConfirmed) => {
                if (isConfirmed) {
                  if (paymentMethodId === "1") {
                    navigate("/thankyou");
                    // setTimeout(() => {
                    //   window.scrollTo(0, 0);
                    // }, 500);
                  } else if (paymentMethodId === "2") {
                    window.location.href = orderData.data.payment_url;
                  } else {
                    navigate("/");
                    setTimeout(() => {
                      window.scrollTo(0, 0);
                    }, 500);
                  }
                }
              },
              timer: 1000,
              timerProgressBar: true,
            }).then((confirm) => {});
          } else {
            Swal.fire({
              // text: "Something Wrong.",
              text: orderData.message,
              icon: "error",
            });
          }
        } catch (error) {
          console.log("error on place order", error);
          Swal.fire({
            text: "Error on Place Order",
            icon: "error",
          });
        } finally {
          setIsButtonDisabled(false);
        }
      }
    } else {
      Swal.fire({
        text: "No Items Available for Checkout",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    getCart(selectedCouponsData?.id);
  }, [couponApplied]);

  const recommendProduct = async (pet_type) => {
    try {
      const bodySend = {
        category_id: "1",
        city: userAddress?.city_name,
        city_id: userAddress?.city_id,
        isMultipleCity: true,
        pet: pet_type,
      };

      const productData = await getProductPlans(bodySend);

      if (productData.success === "1") {
        // Filter out products that are already in the cart
        const filteredProducts = productData.data.filter(
          (product) =>
            !cartData.items.some((item) => item.item_id === product.id)
        );

        const recommendedProduct =
          filteredProducts.length > 0 ? [filteredProducts[0]] : [];

        setRecommendedProducts(recommendedProduct);
      }
    } catch (error) {
      console.log("Error on fetch product", error);
    }
  };

  useEffect(() => {
    recommendProduct(cart_pet);
  }, [userAddress]);

  const RecoPlanAdd = (product) => {
    localStorage.setItem("selectedPlan", JSON.stringify(product));
    navigate("/mypets");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const gotohome = () => {
    navigate("/");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const handlePlaceOrder = async () => {
    if (cartLength > 0) {
      if (validate()) {
        try {
          let newTime;
          const date = cartData.items[0].appoinment_date;
          const time = cartData.items[0].appoinment_time;

          const dateTimeString = `${date} ${time}`;
          const parsedTime = new Date(dateTimeString);
          if (!isNaN(parsedTime)) {
            // Extract hours and minutes
            const hours = parsedTime.getHours();
            const minutes = parsedTime.getMinutes();

            // Format the time to "H:i"
            const newFormattedTime = `${String(hours).padStart(
              2,
              "0"
            )}:${String(minutes).padStart(2, "0")}`;
            newTime = newFormattedTime;
          } else {
            console.error("Invalid date or time format");
          }

          const bodySend = {
            address_id: userAddress.id,
            payment_method_id: paymentMethodId,
            appoinment_date: cartData.items[0].appoinment_date,
            // appoinment_time: newTime,
            appoinment_time: time,
            coupon_code: selectedCouponsData?.id,
          };

          const orderData = await placeOrderTest(bodySend);
          if (orderData.success === "1") {
            Swal.fire({
              title: "Success",
              text: "Confirm Successfully",
              icon: "success",
              showConfirmButton: false,
              confirmButtonText: "OK",
              willClose: (isConfirmed) => {
                if (isConfirmed) {
                  if (paymentMethodId === "1") {
                    navigate("/mybookings");
                    setTimeout(() => {
                      window.scrollTo(0, 0);
                    }, 500);
                  } else if (paymentMethodId === "2") {
                    window.location.href = orderData.data.payment_url;
                  } else {
                    navigate("/");
                    setTimeout(() => {
                      window.scrollTo(0, 0);
                    }, 500);
                  }
                }
              },
              timer: 1000,
              timerProgressBar: true,
            }).then((confirm) => {});
          } else {
            Swal.fire({
              // text: "Something Wrong.",
              text: orderData.message,
              icon: "error",
            });
          }
        } catch (error) {
          console.log("error on place order", error);
          Swal.fire({
            text: "Error on Place Order",
            icon: "error",
          });
        }
      }
    } else {
      Swal.fire({
        text: "No Items Available for Checkout",
        icon: "error",
      });
    }
  };

  return (
    <div className="containerCheckout my-5">
      <div className="row p-2">
        <h3 className="myorder font-bold">My Order</h3>

        {isLoading && (
          <div className="d-flex justify-content-center">
            <ThreeDots color="#7B5BFB" height={50} width={50} />
          </div>
        )}

        {!isLoading && (
          <div>
            {cartData && cartData.items && cartData.items.length > 0 ? (
              cartData.items.map((item) => (
                <div className=" odrer-main my-2" key={item.id}>
                  <div className="order-top my-1 px-3">
                    <div className="order-top-left">
                      {/* <p className="order-title">
                        {item.title}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        ₹ {item.offer_price} /-
                      </p> */}
                      <div className="tp-order">
                        <span className="order-title my-1 bold">
                          {item.title}
                        </span>
                        <span className="order-price my-1 ">
                          ₹ {item.offer_price} /-
                        </span>
                      </div>
                    </div>
                    <div className="odrder-top-right">
                      <img
                        className="cros-remove"
                        src={CrossOrangeIcon}
                        alt="cross"
                        onClick={() => {
                          removeFromCart(item.id);
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="order-bottom mt-2 mb-2"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "1px 10px",
                    }}
                  >
                    <span className="obDesc m-0">
                      <img src={FootPrintIcon} alt="img" className="me-2" />
                      {item.pet_type}
                    </span>
                    <span className="obDesc m-0">
                      <img src={AnimalIcon} alt="img" className="me-2" />
                      {item.pet_name}
                    </span>
                    <span className="obDesc m-0">
                      <img
                        src={TimeCirclecolorIcon}
                        alt="img"
                        className="me-2"
                      />
                      {item.appoinment_date} {"-"} {item.appoinment_time}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center mt-3 notFoundText">
                No items Available
              </p>
            )}
          </div>
        )}
      </div>

      


      {/* {recommendedProducts.length > 0 && (
        <div className="row p-2 mt-2">
          <h5 className="text-left">Add Another Service</h5>
          {recommendedProducts.map((product) => (
            <div key={product.id} className="col-md-12 recomendItem my-2">
              <div className="reco-left">
                <p className="reco-title m-0">{product.title}</p>
                <div className="d-flex reco-bottom-price">
                  <p className="rec-oPrice m-0 me-3">₹ {product.offer_price}</p>
                  <span className="reco-gray me-3">
                    Save Up to ₹{product.price - product.offer_price}
                  </span>{" "}
                  <p className="reco-pri m-0">
                    <s>₹ {product.price}</s>
                  </p>
                </div>
              </div>
              <div className="">
                <img
                  src={PlusIcon}
                  alt="icon"
                  className="reco-plus"
                  onClick={() => {
                    RecoPlanAdd(product);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )} */}

      <div className="row my-2 p-2">
        <h3 className="text-center font-bold mb-2">Check Out</h3>
        <div className="my-2 px-3 text-center">
          <button
            type="submit"
            className="btn text-center ams-btn"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={gotohome}
          >
            Add Service
            <img src={PlusIcon} alt="icon" />
          </button>
        </div>

        
        {/* <div className="accordion-item my-3 couDiv">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <div
              className="col-md-12 couponDiv accordion-button faq_Q collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq1"
              aria-expanded="true"
              aria-controls="faq1"
            >
              <div className="cupLeft">
                <img src={GiftboxIcon} alt="icon" className="me-3" />
                <div className="cupLeftR">
                  {couponApplied ? (
                    <>
                      <p className="cupTopTxt text-white fw-bold m-0">
                        {selectedCouponsData.title}
                      </p>
                      <p className="cupSubTxt fw-bold text-white m-0">
                        Saved ₹{cartData?.discount}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="cupTopTxt text-white fw-bold m-0">Apply Coupon Code</p>
                      <p className="cupSubTxt text-white fw-bold m-0">Check for available offer</p>
                    </>
                  )}
                </div>
              </div>
              <div className="cupRight">
                {couponApplied ? (
                  <img
                    src={CrossFillIcon}
                    alt="icon"
                    onClick={() => {
                      setCouponApplied(false);
                      setSelectedCouponsData();
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </h2>
          <div
            id="faq1"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div className="accordion-body faqAnsBody cupBody text-start">
              {couponsData && couponsData.length > 0 ? (
                couponsData.map((item) => {
                  return (
                    <div key={item.id} className="mycoupon my-2 p-3">
                      <div className="c-top">
                   
                        <img
                          src="https://dearpet.co/uploads/2023/12/a816b82c7ef737ddd514837806b440d6.jpg"
                          alt="img"
                          className="couponImg"
                        />
                        <button
                          className="c-applyBtn"
                          onClick={() => {
                            setCouponApplied(true);
                            setSelectedCouponsData(item);
                          }}
                        >
                          {couponApplied && item.id === selectedCouponsData.id
                            ? "Applied"
                            : "Apply"}
                        </button>
                      </div>
                      <div className="c-bottom">
                        <p className="text-white fw-bold m-0">Coupon Code: {item.code}</p>
                        <p className="text-white fw-bold m-0">Expires: {item.end_date}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-center no-msg">No coupons available</p>
              )}
            </div>
          </div>
        </div> */}

        <div className="summeryCard">
          <div className="summeryTitle">
            <h2>Order Summery</h2>
          </div>
          <div className="flexRow my-1">
            <span className="itemtotal m-0">Item Total</span>
            <span className="ruppe m-0">₹ {cartData?.item_total}</span>
          </div>
          <div className="flexRow my-1">
            <span className="itemtotal m-0">Discount</span>
            <span className="ruppe m-0">₹ {cartData?.discount}</span>
          </div>
          <hr className="hrLine" />
          <div className="flexRow my-2">
            <span className="totaltext m-0">Total</span>
            <span className="totaltext m-0">₹ {cartData?.grand_total}</span>
          </div>
        </div>

        <div className="row-md-12 my-2">
          <p className="addressCheckout m-0">
            <img src={LocationIcon} alt="icon" className="me-2" />
            {userAddress.address}, {userAddress.city_name} -{" "}
            {userAddress.pincode}
          </p>
        </div>

      </div>

      <div className="col my-2 p-2">
        <h3 className="text-center mb-2">Payment Method</h3>
        <div className="col-md-12">
          <div className="methods">
            {cartData?.payment_methods ? (
              cartData.payment_methods
                .slice()
                .reverse()
                .map((item, index) => {
                  const firstIndex = cartData.payment_methods.length - 2;
                  const lastIndex = cartData.payment_methods.length - 1;
                  return (
                    <button
                      key={item.id}
                      className={`paymentBtn text-center ${
                        item.id === paymentMethodId ? "activePaymentBtn" : ""
                      } ${item.id === "3" ? "d-none" : ""} ${
                        index === firstIndex ? "firstBtn" : ""
                      } ${index === lastIndex ? "lastBtn" : ""}`}
                      onClick={() => {
                        setPaymentMethodId(item.id);
                        setPaymentError("");
                      }}
                    >
                      <img
                        src={item.title === "Cash" ? WalletIcon : PayOnlineIcon}
                        alt="icon"
                        className="me-3"
                      />
                      {item.title === "Cash" ? "Pay After Service" : item.title}
                    </button>
                  );
                })
            ) : (
              <></>
            )}
          </div>
          
          {paymentError && (
            <div className="invalid-feedback justify-content-center">
              {paymentError}
            </div>
          )}
        </div>

        <div className=" my-2 mt-3">
          {/* <h4 className="mb-4">Things require at the time of appointment</h4> */}
          <div className="dogInfo">
            <div className="row align-items-center">
              <div className="col-auto">
                <img src={DogImg} alt="Dog" className="dogImg" width={200} />
              </div>
              <div className="col">
                <div className="dogText">
                  <h6 className="dogtxtTitle mb-4">
                    Things require at the time of appointment
                  </h6>
                  <ul className=" m-0 p-0">
                    <li className="dogtxt m-2">Bathing Area</li>
                    <li className="dogtxt m-2">Towel (optional)</li>
                    <li className="dogtxt m-2">A socket for dryer</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <div className="termCheckBox">
              <input
                className="form-check-input me-2 checkBoxT"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                onChange={(e) => {
                  setTermsCondition(e.target.checked);
                  setTermsError("");
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                I have read and agree to the website{" "}
                <a href="/t-policy" target="_blank">
                  terms and conditions
                </a>
              </label>
            </div>
            {termsError && <div className="invalid-feedback">{termsError}</div>}
          </div>

          <div className="my-3 px-3 text-center">
            <button
              type="submit"
              className="btn nextBtn text-center"
              onClick={placeOrderFun}
              disabled={isButtonDisabled}
            >
              {isButtonDisabled ? "Please wait..." : "Place Order"}
            </button>
          </div>

          {cartData && cartData.items && cartData.items.length > 0 ? (
            <></>
          ) : (
            <div className="my-3 px-3 text-center">
              <button
                type="submit"
                className="btn nextBtn text-center"
                onClick={gotohome}
              >
                Go To Home
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyOrder;
